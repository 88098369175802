import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthModule } from './components/auth/auth.module';
import { LoadingBarComponent } from './components/loading-bar/loading-bar.component';
import { InterceptorService } from './services/interceptor.service';
import { NotificationsModule } from './notifications/notifications.module';
import { DashboardModule } from './components/dashboard/dashboard.module';
import { CacheInterceptorService } from './services/cache-interceptor.service';
import { MenuComponent } from './components/dashboard/menu/menu.component';
import { SidenavService } from './services/sidenav.service';
import { CloudinaryModule, CloudinaryConfiguration } from '@cloudinary/angular-5.x';
import { Cloudinary } from 'cloudinary-core';
import { UpdateImageService } from './services/updateimage.service';

@NgModule({
    declarations: [
        AppComponent,
        LoadingBarComponent,
        MenuComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        AuthModule,
        DashboardModule,
        NotificationsModule,
        CloudinaryModule.forRoot({Cloudinary}, { cloud_name: 'dkkfi0ryp' } as CloudinaryConfiguration),
        // TooltipModule
    ],
    providers: [
        {provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: CacheInterceptorService, multi: true},
        SidenavService,
        UpdateImageService
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
