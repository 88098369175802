import { Component, Input, OnInit, ViewEncapsulation, } from '@angular/core';
import { OpendialogService } from 'src/app/services/opendialog.service';
import { ImageUrl } from '../../lang/globals-en';
import { EditDialogComponent } from '../dialogs/edit-dialog/edit-dialog.component';
import { PositionService } from 'src/app/services/position.service';
import { IndustryService } from 'src/app/services/industry.service';

@Component({
    selector: 'app-tooltip',
    templateUrl: './tooltip.component.html',
    styleUrls: ['./tooltip.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class TooltipComponent implements OnInit {

    @Input() data: any;
    @Input() typePosition: any = '';
    imageUrl = ImageUrl;
    imageUrlType;
    allPosition = [];
    allIndustries = [];
    constructor(
        private openDialogService: OpendialogService,
        private positionService: PositionService,
        private industryService: IndustryService) { }

    ngOnInit(): void {
        if(this.typePosition.group == 'brands'){
            this.imageUrlType = this.imageUrl.brandsIcon;
        }else if(this.typePosition.group == 'people' || this.typePosition.group == 'person-key-contact') {
            this.imageUrlType = this.imageUrl.peopleIcon;
        }else{
            this.imageUrlType = this.imageUrl.companyIcon;
        }
    }

    editModal(){
        const ssData = this.data;
        const allSingleData = this.data;
        const allCompanyTypes = [];

        if(this.typePosition.group == 'people' || this.typePosition.group == "person-key-contact"){
            this.positionService.index().subscribe((res: any) => {
                this.allPosition = res.data;
            })
        }

        if(this.typePosition.group == 'brands'){
            this.industryService.index().subscribe((res: any) => {
                this.allIndustries = res.data;
            })
        }

        this.openDialogService.openUpdateModal(this.typePosition, EditDialogComponent, ssData, this.allPosition, allCompanyTypes, allSingleData, this.allIndustries);
    }

}
