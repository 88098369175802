<div class="add-dialog">
    <div class="add-dialog-header">
        <button mat-dialog-close matDialogClose class="close">
            <span aria-hidden="true">×</span>
        </button>
        <h3 mat-dialog-title>Add new {{ type | titlecase }}</h3>
    </div>
    
    <div mat-dialog-content class="add-dialog-content">
        <form [formGroup]="modalFormGroup" (submit)="submit()" id="ngForm">
            <div class="body-content">
                <div class="inputs-wrapper">
                    <div class="modal-info">

                        <mat-form-field class="example-chip-list" appearance="standard" *ngIf="type == 'service'">
                            <mat-label class="label">Services</mat-label>
                            <mat-chip-list #chipList aria-label="Service selection" formControlName="services" required>
                                <mat-chip *ngFor="let item of items" (removed)="removeItem(item)">
                                    {{item}}
                                    <button matChipRemove>
                                        <mat-icon>cancel</mat-icon>
                                    </button>
                                </mat-chip>
                                <input
                                    placeholder="Add New Service..."
                                    #dataTypeInput
                                    [formControl]="typeFrom"
                                    formControlName="services"
                                    [matChipInputFor]="chipList"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    (matChipInputTokenEnd)="addDataItem($event)"
                                    class="input" required>
                            </mat-chip-list>
                        </mat-form-field>

                        <mat-form-field class="example-chip-list" appearance="standard" *ngIf="type == 'industry'">
                            <mat-label class="label">Industries</mat-label>
                            <mat-chip-list #chipList aria-label="Industry selection" formControlName="industries" required>
                                <mat-chip *ngFor="let item of items" (removed)="removeItem(item)" class="industryItem">
                                    {{item}}
                                    <button matChipRemove>
                                        <mat-icon>cancel</mat-icon>
                                    </button>
                                </mat-chip>
                                <input
                                    placeholder="Add New Industry..."
                                    #dataTypeInput
                                    [formControl]="typeFrom"
                                    formControlName="industries"
                                    [matChipInputFor]="chipList"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    (matChipInputTokenEnd)="addDataItem($event)"
                                    class="input" required>
                            </mat-chip-list>
                        </mat-form-field>

                        <mat-form-field class="example-chip-list" appearance="standard" *ngIf="type == 'type'">
                            <mat-label class="label">Types</mat-label>
                            <mat-chip-list #chipList aria-label="Type selection" formControlName="types" required>
                                <mat-chip *ngFor="let item of items" (removed)="removeItem(item)">
                                    {{item}}
                                    <button matChipRemove>
                                        <mat-icon>cancel</mat-icon>
                                    </button>
                                </mat-chip>
                                <input
                                    placeholder="Add New Type..."
                                    #dataTypeInput
                                    [formControl]="typeFrom"
                                    formControlName="types"
                                    [matChipInputFor]="chipList"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    (matChipInputTokenEnd)="addDataItem($event)"
                                    class="input" required>
                            </mat-chip-list>
                        </mat-form-field>

                    </div>
                </div>
            </div>
            <div mat-dialog-actions align="end">
                <button mat-raised-button form="ngForm" class="f-sub-header-3" color="primary" type="submit" [disabled]="!modalFormGroup.valid">Save</button>
            </div>
        </form>
    </div>
</div>


