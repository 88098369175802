import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Config } from '../config';
import { TokenService } from './auth/token.service';
import { CacheResolverService } from './cache-resolver.service';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {
    config:Config;
    public reloadCompanyTable: Subject<any> = new Subject();

    constructor(private http: HttpClient, 
        private token: TokenService,
        private cacheResolver: CacheResolverService) { }

    headers = new HttpHeaders({'Content-Type': 'application/json', 'Authorization':`Bearer ${this.token.get()}`});

    index(){
        return this.http.get(Config.url + 'companies', {headers:this.headers});
    }
    
    getListByType(type){
        return this.http.get(Config.url + 'companies/getJson/list/' + type, {headers:this.headers});
    }

    store(data){
        return this.http.post(Config.url + 'companies', data, {headers:this.headers});
    }

    show(slug){
        return this.http.get(Config.url + 'companies/' + slug, {headers:this.headers});
    }

    isClient(slug){
        return this.http.get(Config.url + 'companies/' + slug + '/getJson/client', {headers:this.headers});
    }

    removeClient(slug){
        return this.http.delete(Config.url + 'companies/' + slug + '/getJson/client', {headers:this.headers});
    }

    removeRelationship(slug, params, id){
        return this.http.post(Config.url + 'companies/' + slug + '/relationships/' + params + '/detach/' + id, params, {headers:this.headers});
    }

    // getRelationshipsByType(type, slug, companyType){
    //     return this.http.get(Config.url + type + '/' + slug + '/relationships/companies/' + companyType + '/byType' , {headers:this.headers});
    // }
    
    getRelationships(type, slug, companyType){
        return this.http.get(Config.url + type + '/' + slug + '/relationships/companies/' + companyType, {headers:this.headers});
    }

    getRelationshipsLimitData(type, slug, companyType){
        return this.http.get(Config.url + type + '/' + slug + '/relationships/companies/' + companyType + '/limitData', {headers:this.headers});
    }

    getRelationshipsAllData(type, slug){
        return this.http.get(Config.url + type + '/' + slug + '/relationships/people/alldata', {headers:this.headers});
    }
    
    getCollaboratorsLimitData(slug){
        return this.http.get(Config.url + 'companies/' + slug + '/relationships/collaborators/limitData', {headers:this.headers});
    }
    
    getCollaboratorsAllData(slug){
        return this.http.get(Config.url + 'companies/' + slug + '/relationships/collaborators/allData', {headers:this.headers});
    }

    update(slug, data){
        return this.http.put(Config.url + 'companies/' + slug, data, {headers:this.headers}); 
    }

    delete(slug){
        return this.http.delete(Config.url + 'companies/' + slug, {headers:this.headers}); 
    }

    deleteError(data){
        return this.http.post(Config.url + 'companies/data/error', data, {headers:this.headers}); 
    }

    refreshRelCompaniesCache(companyType){
        // this.cacheResolver.delete(Config.url + type + '/' + slug + '/relationships/companies/' + companyType + '/limitData')
        // this.cacheResolver.delete(Config.url + type + '/' + slug + '/relationships/companies/' + companyType)
        this.refreshRelationsByTypeCache(companyType)
        this.refreshAllCollaboratorsCache()
        this.refreshJsonCompaniesCache(companyType)
    }

    refreshRelCollaborators(slug, companyType){
        this.cacheResolver.delete(Config.url + 'companies/' + slug + '/relationships/collaborators/limitData')
        this.cacheResolver.delete(Config.url + 'companies/' + slug + '/relationships/collaborators/allData')
        this.cacheResolver.delete(Config.url + 'companies/getJson/list/' + companyType);
    }
    
    refreshJsonCompaniesCache(companyType){
        this.cacheResolver.delete(Config.url + 'companies/getJson/list/' + companyType)
    }

    refreshClientCache(slug){
        this.cacheResolver.delete(Config.url + 'companies/' + slug + '/getJson/client')
    }
    
    refreshCompanyCache(slug){
        this.cacheResolver.delete(Config.url + 'companies/' + slug);
    }

    refreshUpdateCompanyCache(slug){
        this.cacheResolver.delete(Config.url + 'companies/' + slug);
        this.cacheResolver.delete(Config.url + 'companies');
        this.reloadCompanyTable.next(true);
    }

    refreshRelationsByTypeCache(companyType){
        const cacheItems = { ...localStorage };
        const keysArray = Object.keys(cacheItems);
        for (let i = 0; i < keysArray.length; i++) {
            if(keysArray[i].includes('/relationships/companies/' + companyType)){
                this.cacheResolver.delete(keysArray[i])
            }
        }
    }

    refreshAllCollaboratorsCache(){
        const cacheItems = { ...localStorage };
        const keysArray = Object.keys(cacheItems);
        for (let i = 0; i < keysArray.length; i++) {
            if(keysArray[i].includes('/relationships/collaborators/')){
                this.cacheResolver.delete(keysArray[i])
            }
        }
    }
}
