<div class="single-brand single-section active">
    <div class="brand-container single-container">
        <h2 class="f-sub-title-1">Brands</h2>
        <div class="brands">
            <app-loading-module class="loader" [ngClass]="{'loaded': loadedContent}"></app-loading-module>

            <div class="brands-wrapper" id="brands-wrapper" [ngClass]="{'hidden': !loadedContent}">
                <ng-container *ngFor="let brand of brands; let i = index">
                    <div class="single-brand toolBrand" *ngIf="i < 14"
                        (click)="toggleRightSidenav(brand)" data-tippy-delay="[350, 350]"
                        [ngxTippy]="tippyTemplate" [tippyProps]="tippyContent">
                        <button class="delete-button" (click)="deleteRelationship(brand.slug, $event)" >
                            <img src="./../../../../../assets/img/off_close.png" alt="delete">
                        </button>
                        <div class="brand-logo">
                            <ng-container *ngIf="brand.logo; else noLogoImg">
                                <cl-image _ngcontent-ywn-2="" public-id="mypic" class="thumbnail inline"
                                    ng-reflect-public-id="mypic">
                                    <img src="{{imageUrl}}/{{ brand.logo }}">
                                </cl-image>
                            </ng-container>
                            <ng-template #noLogoImg>
                                <img src="./../../../../../assets/img/default-logo.png" alt="avatar">
                            </ng-template>
                        </div>
                        <template #tippyTemplate>
                            <app-tooltip [data]="brand" [typePosition]="typeGroup"></app-tooltip>
                        </template>
                    </div>
                    <ng-container *ngIf="i == 14">
                        <div class="single-brand toolBrand" (click)="toggleRightSidenav(brand)"
                            *ngIf="brands.length == 15; else viewAllContent" data-tippy-delay="[350, 350]"
                            [ngxTippy]="tippyTemplate" [tippyProps]="tippyContent">
                            <button class="delete-button" (click)="deleteRelationship(brand.slug, $event)" >×</button>
                            <div class="brand-logo">
                                <ng-container *ngIf="brand.logo; else noLogoImg">
                                    <cl-image _ngcontent-ywn-2="" public-id="mypic" class="thumbnail inline"
                                        ng-reflect-public-id="mypic">
                                        <img src="{{imageUrl}}/{{ brand.logo }}">
                                    </cl-image>
                                </ng-container>
                                <ng-template #noLogoImg>
                                    <img src="./../../../../../assets/img/default-logo.png" alt="avatar">
                                </ng-template>
                            </div>
                        </div>
                        <ng-template #viewAllContent>
                            <div class="more-brands">
                                <div class="view-all-button">
                                    <p class="brand-count f-sub-header-2 font-blue"> 
                                        + <span> {{ dataCount - 14}}</span>
                                    </p>
                                    <a class="view-all-brands" (click)="showAllList()"> 
                                        <img src="./../../../../../assets/img/view-all.png" alt="View All"> 
                                    </a>
                                </div>
                            </div>
                        </ng-template>
                    </ng-container>
                </ng-container>
                <em *ngIf="!brands" class="no-brands f-body-2">No Brands</em>

                <div class="brand-input" [ngClass]="{open: openInput}">
                    <mat-form-field appearance="standard">
                        <mat-label>New Brand</mat-label>
                        <input matInput type="text" #inputField #trigger="matAutocompleteTrigger"
                            (keyup.enter)="onInputEnter($event.target.value)" [formControl]="formControl"
                            [matAutocomplete]="auto">
                        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                            <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                                {{option.name}}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
                <button class="f-button font-blue add"
                    [ngClass]="{'open': openInput, 'view-all-and-add-key': brands && dataCount > 15}"
                    (click)="add()">+Add</button>
                <button class="f-button font-blue save" [ngClass]="{open: openInput}" (click)="submit()">Save</button>
            </div>
        </div>
    </div>
</div>

<app-sidenav [dataNav]="brandClicked"></app-sidenav>