
export const ImageUrl = Object.freeze({
    people: 'https://res.cloudinary.com/dkkfi0ryp/image/upload/w_180,h_180,c_thumb/v1658137685/network/person-avatars/',
    peopleIcon: 'https://res.cloudinary.com/dkkfi0ryp/image/upload/w_75,h_75,c_thumb/v1658137685/network/person-avatars/',
    brands: 'https://res.cloudinary.com/dkkfi0ryp/image/upload/w_180,h_180,c_thumb/v1658137685/network/brand-logos/',
    brandsIcon: 'https://res.cloudinary.com/dkkfi0ryp/image/upload/w_75,h_75,c_thumb/v1658137685/network/brand-logos/',
    company: 'https://res.cloudinary.com/dkkfi0ryp/image/upload/w_180,h_180,c_thumb/v1658137685/network/company-logos/',
    companyIcon: 'https://res.cloudinary.com/dkkfi0ryp/image/upload/w_75,h_75,c_thumb/v1658137685/network/company-logos/',
});

export const NotificationText = Object.freeze({
    logged_in: 'Successfully logged in',
    logged_in_error: 'Data was not valid! Please try again',
    registered: 'Successfully registered',
    register_error: 'Data was not valid! Please try again',
    reseted: 'Email Sent! Please check your email',
    reset_error: 'Data was not valid! Please try again',
    changed: 'Password changed successfully',
    change_error: 'Data was not valid! Please try again',
    relationship: 'Relationship created successfully',
    relationship_err: 'Relationship was not created! Please try again',
    relationshipDel: 'Relationship was deleted successfully',
    relationshipDel_err: 'Relationship was not deleted! Please try again',
    client_added: 'Client added sucessfully!',
    client_added_err: 'Client was not added! Please try again',  
    client_removed: 'Client removed sucessfully!',
    client_removed_err: 'Client was not removed! Please try again',
    review_added: 'Review added sucessfully!',
    review_added_err: 'Review was not added! Please try again',  
});

export const LoginText = Object.freeze({
    header: 'Hello',
    header_err: 'Oops',
    email: 'Email',
    email_req: 'Email is required',
    email_valid: 'Please write a valid email',
    password: 'Password',
    password_req: 'Password is required',
    password_valid: 'Minimum length for password not reached!',
    buttonText: 'Log in',
    logged_in: 'Keep me logged in',
    forgot: 'Forgot Password?',
    member: 'Not a member?',
    signup: 'Sign up'
});

export const RegisterText = Object.freeze({
    header: 'Register',
    header_err: 'Oops',
    name: 'Name',
    name_req: 'Name is required',
    email: 'Email',
    email_req: 'Email is required',
    email_valid: 'Please write a valid email',
    password: 'Password',
    password_req: 'Password is required',
    password_valid: 'Minimum length for password not reached!',
    c_password: 'Confirm Password',
    c_password_req: 'Confirm Password is required',
    c_password_valid: 'Minimum length for confirm password not reached!',
    buttonText: 'Register',
    terms: 'I agree to the Terms of Service and Privacy Policy',
    member: 'Already a member?',
    login: 'Log in'
});

export const ResetText = Object.freeze({
    header: 'Enter Your Email',
    header_change: 'Change Password',
    email: 'Email',
    email_req: 'Email is required',
    email_valid: 'Please write a valid email',
    password: 'Password',
    password_req: 'Password is required',
    password_valid: 'Minimum length for password not reached!',
    c_password: 'Confirm Password',
    c_password_req: 'Confirm Password is required',
    c_password_valid: 'Minimum length for confirm password not reached!',
    buttonText: 'Sent Email',
    buttonText_change: 'Change Password',
    reset: 'Not needing a reset?',
    login: 'Log in'
});

export const PeopleText = Object.freeze({
    title: 'People',
    noMsg: 'No people',
    new: 'New person',
    add: 'Add',
    save: 'Save',
    added: 'Person added successfully',
    add_err: 'Person was not added! Please try again',
    updated: 'Person is updated successfully',
    update_err: 'Person was not updated! Please try again',
    removed: 'Person is removed!',
    remove_err: 'Person was not removed! Please try again'
});

export const KeyContactsText = Object.freeze({
    title: 'Key Contacts',
    noMsg: 'No key contacts',
    new: 'New key contact',
    add: 'Add',
    save: 'Save'
});

export const BrandsText = Object.freeze({
    add: 'Add a Brand',
    added: 'Brand added successfully',
    add_err: 'Brand was not added! Please try again',
    updated: 'Brand is updated successfully',
    update_err: 'Brand was not updated! Please try again',
    removed: 'Brand is removed',
    remove_err: 'Brand was not removed! Please try again'
});

export const ServicesText = Object.freeze({
    add: 'Add a Service',
    added: 'Services added successfully',
    add_err: 'Services were not added! Please try again',
    removed: 'Service is removed!',
    remove_err: 'Service was not removed! Please try again'
});

export const IndustriesText = Object.freeze({
    add: 'Add a Industry',
    added: 'Industries added successfully',
    add_err: 'Industries were not added! Please try again',
    removed: 'Industry is removed!',
    remove_err: 'Industry was not removed! Please try again'
});

export const CompaniesText = Object.freeze({
    added: 'Company added successfully',
    add_err: 'Company was not added! Please try again',
    updated: 'Company was updated successfully',
    update_err: 'Company was not updated! Please try again',
    removed: 'Company is removed!',
    remove_err: 'Company was not removed! Please try again'
});

export const CompanyTypesText = Object.freeze({
    added: 'Company Types added successfully',
    add_err: 'Company Types were not added! Please try again',
    removed: 'Company Type is removed!',
    remove_err: 'Company Type was not removed! Please try again',
    updated: 'Company Types updated successfully',
    updated_err: 'Company Type was not updated! Please try again',
});