import { Component, HostListener, Input, OnInit } from '@angular/core';
import { BrandsText, CompaniesText, CompanyTypesText, ImageUrl, NotificationText, PeopleText } from 'src/app/components/lang/globals-en';
import { OpendialogService } from 'src/app/services/opendialog.service';
import { SmallDialogComponent } from '../../dialogs/small-dialog/small-dialog.component';
import { ClientService } from 'src/app/services/client.service';
import { BrandService } from 'src/app/services/brand.service';
import { NotifierService } from 'angular-notifier';
import { PersonService } from 'src/app/services/person.service';
import { CompanyService } from 'src/app/services/company.service';
import { ReviewService } from 'src/app/services/review.service';
import { GeneralService } from 'src/app/services/general.service';
import { EditDialogComponent } from '../../dialogs/edit-dialog/edit-dialog.component';
import { IndustryService } from 'src/app/services/industry.service';
import { PositionService } from 'src/app/services/position.service';

@Component({
    selector: 'app-ss-header',
    templateUrl: './ss-header.component.html',
    styleUrls: ['./ss-header.component.scss']
})
export class SsHeaderComponent implements OnInit {

    @Input() typeData;
    @Input() type;
    @Input() typeSingle;
    @Input() typeGroup;
    isClient:boolean;
    imageUrl = ImageUrl;
    notifications = NotificationText;
    personNotifications = PeopleText;
    brandNotifications = BrandsText;
    companyNotifications = CompaniesText;
    averageRate;
    rate = 0;
    prevRate = 0;
    isGmail;
    settingsActive:boolean = false;
    allPosition = [];
    allIndustries = [];
    private subscribtion: any;
    private ratingSubscribtion: any;
    private updateSubscribtion:any;
    private updateModalSubscribtion:any;


    constructor(private dialogService: OpendialogService,
        private clientService: ClientService,
        private brandService: BrandService,
        private notifier: NotifierService,
        private personService: PersonService,
        private companyService: CompanyService,
        private reviewService: ReviewService,
        private generalService: GeneralService,
        private openDialogService: OpendialogService,
        private positionService: PositionService,
        private industryService: IndustryService) {

        this.ratingSubscribtion = this.dialogService.reviewData.subscribe((res: any) => {
            if(res.data.button === 'close'){
                this.rate = res.data.prevRate            
            }else{
                this.rate = res.data.rate,
                this.prevRate = res.data.prevRate;
                let id = res.data.singleIDModalData;
                
                this.addRating(this.rate, id);
            }
        });

        this.subscribtion = this.dialogService.clientData.subscribe((res: any) => {
            let clientId = res.data.singleClientData.id;
            if(res.data.clientStatus === "removedClient") {
                this.removeClient();
            } else {
                this.addClient(clientId);
            }
        });

        this.updateModalSubscribtion = this.openDialogService.updateModalData.subscribe((res: any) => {
            if(res.type.group == this.typeGroup.group &&  res.type.group == "people"){
                this.updatePerson(res.slug, res.data, res.positionChanged)
            }
            if(res.type.group == this.typeGroup.group &&  res.type.group == 'brands'){
                this.updateBrand(res.slug, res.data)
            }
            if (res.type.type.name == this.typeGroup.type.name && res.type.group == "company") {
                this.updateCompany(res.slug, res.data)
            }
        })

        this.personService.reloadPersonPositions.subscribe(res => {
            this.typeData.positions = res
        })

        this.brandService.reloadBrandIndustries.subscribe(res => {
            this.typeData.industries = res
        })
    }

    ngOnInit(): void {
        this.isProfileClient();
        this.userReview();
        this.checkEmail();
        setTimeout(() => {
            this.averageReview();
        }, 200);
    }
    
    ngOnDestroy(): void {
        if (this.subscribtion) {
            this.subscribtion.unsubscribe();
        }
        if (this.ratingSubscribtion) {
            this.ratingSubscribtion.unsubscribe();
        }
        if (this.updateSubscribtion) {
            this.updateSubscribtion.unsubscribe();
        }
        if (this.updateModalSubscribtion) {
            this.updateModalSubscribtion.unsubscribe();
        }
    }

    isProfileClient(){
        if(this.type === 'brands') {
            this.brandService.isClient(this.typeData.slug).subscribe((res: any) => {
                this.isClient = res.isClient;
            });
        } else if(this.type === 'companies') {
            this.companyService.isClient(this.typeData.slug).subscribe((res: any) => {
                this.isClient = res.isClient;
            });
        } else {
            this.personService.isClient(this.typeData.slug).subscribe((res: any) => {
                this.isClient = res.isClient;
            });
        }
    }

    addClientModal(){
        this.dialogService.openClientDialog('client', SmallDialogComponent, this.isClient, this.typeData);
    }

    removeClientModal(){
        this.dialogService.openClientDialog('client', SmallDialogComponent, this.isClient, this.typeData);
    }

    addClient(id){
        let data;
        if(this.type === 'brands'){
            data = {type: 'brand_id', brand_id: id};
        } else if(this.type === 'companies'){
            data = {type: 'company_id', company_id: id};
        } else {
            data = {type: 'person_id', person_id: id};
        }
        
        this.clientService.store(data).subscribe(
            data => this.handleResponseAddClient(data),
            error => this.handleErrorAddClient(error),
        );
    }

    handleResponseAddClient(data) {
        this.isClient = true;
        this.notifier.notify('success', this.notifications.client_added);

        if(this.type === 'brands'){
            this.brandService.refreshClientCache(this.typeData.slug)
        } else if(this.type === 'companies'){
            this.companyService.refreshClientCache(this.typeData.slug)
        } else {
            this.personService.refreshClientCache(this.typeData.slug)
        }
    }

    handleErrorAddClient(error) {
        this.notifier.notify('error', this.notifications.client_added_err);
    }

    removeClient(){
        if(this.type === 'brands'){
            this.brandService.removeClient(this.typeData.slug).subscribe(
                data => this.handleResponseRemoveClient(data),
                error => this.handleErrorRemoveClient(error),
            );
        } else if(this.type === 'companies'){
            this.companyService.removeClient(this.typeData.slug).subscribe(
                data => this.handleResponseRemoveClient(data),
                error => this.handleErrorRemoveClient(error),
            );
        } else {
            this.personService.removeClient(this.typeData.slug).subscribe(
                data => this.handleResponseRemoveClient(data),
                error => this.handleErrorRemoveClient(error),
            );
        }
    }
    
    handleResponseRemoveClient(data) {
        this.isClient = false;
        this.notifier.notify('success', this.notifications.client_removed);

        if(this.type === 'brands'){
            this.brandService.refreshClientCache(this.typeData.slug)
        } else if(this.type === 'companies'){
            this.companyService.refreshClientCache(this.typeData.slug)
        } else {
            this.personService.refreshClientCache(this.typeData.slug)
        }
    }

    handleErrorRemoveClient(error) {
        this.notifier.notify('error', this.notifications.client_removed_err);
    }

    
    userReview(){
        if(this.type === 'brands'){
            this.reviewService.getUserReview('brand_id', this.typeData.id).subscribe((res: any) => {
                this.rate = res;    
                this.prevRate = res;                
            });
        } else if(this.type === 'companies'){
            this.reviewService.getUserReview('company_id', this.typeData.id).subscribe((res: any) => {
                this.rate = res;    
                this.prevRate = res;
            });
        } else {
            this.reviewService.getUserReview('person_id', this.typeData.id).subscribe((res: any) => {
                this.rate = res;    
                this.prevRate = res;
            });
        }
    }

    averageReview(){
        if(this.type === 'brands'){
            this.reviewService.getAvarage('brand_id', this.typeData.id).subscribe((res: any) => {
                this.averageRate = res;
            });
        } else if(this.type === 'companies'){
            this.reviewService.getAvarage('company_id', this.typeData.id).subscribe((res: any) => {
                this.averageRate = res;
            });
        } else {
            this.reviewService.getAvarage('person_id', this.typeData.id).subscribe((res: any) => {
                this.averageRate = res;
            });
        }
    }

    clickedRate(){
        this.dialogService.openReviewDialog('review', SmallDialogComponent, this.rate, this.prevRate, this.typeData);
    }
    
    closeRatingModal(){
        this.rate = this.prevRate;
    }
    
    @HostListener('window:keyup.esc') onKeyUp() {
        this.closeRatingModal();
    }

    addRating(rating,id){
        let data;
        if(this.type === 'brands'){
            data = {rating: rating, brand_id: id};
        } else if(this.type === 'companies'){
            data = {rating: rating, company_id: id};
        } else {
            data = {rating: rating, person_id: id};
        }

        this.reviewService.store(data).subscribe(
            data => this.handleResponseAddRating(data, id),
            error => this.handleErrorAddRating(error)
        );
    }

    handleResponseAddRating(data, id){
        this.rate = data;
        this.prevRate = data;
        this.notifier.notify('success', this.notifications.review_added);
        
        if(this.type === 'brands'){
            this.reviewService.refreshReviewCache('brand_id', id);
        } else if(this.type === 'companies'){
            this.reviewService.refreshReviewCache('company_id', id);
        } else {
            this.reviewService.refreshReviewCache('person_id', id);
        }

        setTimeout(() => {
            this.averageReview();
        }, 200);
        this.closeRatingModal();

    }
    handleErrorAddRating(error){
        this.notifier.notify('error', this.notifications.review_added_err);
    }

    checkEmail(){
        this.isGmail = false;
        if(this.typeData.email){
            const emailData = {
                email: this.typeData.email
            }
            this.generalService.checkEmailDns(emailData).subscribe((res: any) => {
                this.isGmail = res;
            })
        }
    }

    edit(){
        const allSingleData = this.typeData;
        const allCompanyTypes = [];

        if(this.typeGroup.group == 'people'){
            this.positionService.index().subscribe((res: any) => {
                this.allPosition = res.data;
            })
        }
        
        if(this.typeGroup.group == 'brands'){
            this.industryService.index().subscribe((res: any) => {
                this.allIndustries = res.data;
            })
        }

        this.openDialogService.openUpdateModal(this.typeGroup, EditDialogComponent, this.typeData, this.allPosition, allCompanyTypes, allSingleData, this.allIndustries);
    }

    updatePerson(id, data, positionChanged){
        this.personService.refreshRelPeopleCache(positionChanged);
        this.updateSubscribtion = this.personService.update(id, data).subscribe(
            data => this.handlePersonUpdateResponse(data),
            error => this.handlePersonUpdateError(error),
        );
    }

    handlePersonUpdateResponse(data) {
        this.notifier.notify('success', this.personNotifications.updated);
        this.updateSubscribtion.unsubscribe();
        this.typeData = data.data
        console.log(this.typeData);
        
        this.personService.refreshPersonCache(data.data.slug);
        this.positionService.refreshRelPositionsCache(this.type, this.typeData.slug);
        this.positionService.reloadRelPositions.next();
    }
    handlePersonUpdateError(error) {
        this.notifier.notify('error', this.personNotifications.update_err);
        this.updateSubscribtion.unsubscribe();
    }
    
    updateBrand(id, storeData){
        this.brandService.refreshRelBrandsCache();
 
        this.updateSubscribtion = this.brandService.update(id, storeData).subscribe(
            data => this.handleBrandUpdateResponse(data),
            error => this.handleBrandUpdateError(error, storeData),
        );
    }
    
    handleBrandUpdateResponse(data) {
        this.notifier.notify('success', this.brandNotifications.updated);
        this.updateSubscribtion.unsubscribe();
        this.typeData = data.data
        this.brandService.refreshBrandCache(data.data.slug);
        this.industryService.refreshRelIndustriesCache(this.type, this.typeData.slug);
        this.industryService.reloadIndustries.next();
    }
    handleBrandUpdateError(error, data) {
        this.notifier.notify('error', this.brandNotifications.update_err);
        this.updateSubscribtion.unsubscribe();
    }

    updateCompany(id, data){
        this.companyService.refreshRelCompaniesCache(this.typeGroup.type.slug);
        this.updateSubscribtion = this.companyService.update(id, data).subscribe(
            data => this.handleCompanyUpdateResponse(data),
            error => this.handleCompanyUpdateError(error, data),
        );
    }
    
    handleCompanyUpdateResponse(data) {
        this.notifier.notify('success', this.companyNotifications.updated);
        this.updateSubscribtion.unsubscribe();
        this.companyService.refreshCompanyCache(data.data.slug);
        this.typeData = data.data
    }
    handleCompanyUpdateError(error, data) {
        this.notifier.notify('error', this.companyNotifications.update_err);
        this.updateSubscribtion.unsubscribe();
    }

    sendOffer(){
        console.log('send offer');
    }

    offersHistory(){
        console.log('offers history');
    }

    delete(){
        console.log('delete');
    }
}
