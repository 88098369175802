import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Config } from '../config';
import { TokenService } from './auth/token.service';
import { CacheResolverService } from './cache-resolver.service';

@Injectable({
    providedIn: 'root'
})
export class CompanyTypesService {
    config: Config;
    public refreshCompanyType: Subject<any> = new Subject();

    constructor(private http: HttpClient, private token: TokenService,
        private cacheResolver: CacheResolverService) { }

    headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${this.token.get()}` });

    index() {
        return this.http.get(Config.url + 'company-types', { headers: this.headers });
    }

    getList(token = null){
        if(token){
            this.headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` });
        }
        return this.http.get(Config.url + 'company-types/getJson/list', {headers:this.headers});
    }

    store(data) {
        return this.http.post(Config.url + 'company-types', data, { headers: this.headers });
    }

    show(slug) {
        return this.http.get(Config.url + 'company-types/' + slug, { headers: this.headers });
    }

    update(data, slug) {
        return this.http.put(Config.url + 'company-types/' + slug, data, { headers: this.headers });
    }

    delete(slug) {
        return this.http.delete(Config.url + 'company-types/' + slug, { headers: this.headers });
    }
    
    deleteError(data){
        return this.http.post(Config.url + 'company-types/data/error', data, {headers:this.headers});
    }

    refreshCompanyTypesCache(){
        this.cacheResolver.delete(Config.url + 'company-types/getJson/list')
        this.cacheResolver.delete(Config.url + 'company-types')
    }

    refreshTypeCache(slug){
        console.log('my slug', slug);
        
        this.cacheResolver.delete(Config.url + 'companies/getJson/list/' + slug)
    }

    refreshOnUpdateCompany(){
        this.cacheResolver.delete(Config.url + 'companies');
    }
}
