<div class="edit-dialog">
    <div class="edit-dialog-header">
        <button mat-dialog-close matDialogClose class="close">
            <span aria-hidden="true">×</span>
        </button>
        <ng-container *ngIf="data.allSingleData.name">
            <h3 mat-dialog-title>Edit {{data.allSingleData.name}}</h3>
        </ng-container>
    </div>
    <div mat-dialog-content class="edit-dialog-content">
        <form [formGroup]="modalFormGroup" (submit)="submit()" id="ngForm">
            <div class="body-content">
                <div class="modal-profile">
                    <div class="modal-avatar--label">
                        <ng-container *ngIf="type.group == 'people' || type.group== 'person-key-contact'; else logoText">
                            <p>Avatar</p>
                        </ng-container>
                        <ng-template #logoText>
                            <p>Logo</p>
                        </ng-template>
                    </div>
                    <div class="modal-maxsize--info">
                        <p> 2MB</p>
                    </div>
                    <div class="logo" [ngClass]="(type.group == 'people' || type.group== 'person-key-contact') ? 'add-avatar-wrapper': 'add-logo-wrapper'">
                        <file-pond #myPond [options]="pondOptions" [files]="pondFiles"
                            (onaddfile)="filePondAddFile($event)"
                            (onremovefile)="filePondRemoveFilde($event)">
                        </file-pond>
                    </div>
                </div>
                <div class="inputs-wrapper">
                    <div class="modal-info">
                        <mat-form-field class="full-width-input" appearance="standard">
                            <mat-label class="label">Name</mat-label>
                            <input matInput type="text" autocomplete="new-password" class="input" formControlName="name"
                                #nameInput required [autofocus]="focusInput">
                            <mat-error class="error" *ngIf="(modalFormGroup.get('name')).hasError('required')">Name is
                                required</mat-error>
                        </mat-form-field>
                        <mat-form-field class="example-chip-list" appearance="standard" *ngIf="type == 'company' && !companyTypesData">
                            <mat-label class="label">Type</mat-label>
                            <mat-chip-list #chipList aria-label="Company type selection" formControlName="companyTypes" required>
                                <mat-chip *ngFor="let companyType of companyTypes"
                                    (removed)="removeCompanyType(companyType)">
                                    {{companyType}}
                                    <button matChipRemove>
                                        <mat-icon>cancel</mat-icon>
                                    </button>
                                </mat-chip>
                                <input placeholder="New Company Type..." #companyTypeInput matInput formControlName="companyTypes"
                                    [formControl]="companyType" [matAutocomplete]="auto" [matChipInputFor]="chipList"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    (matChipInputTokenEnd)="addCompanyType($event)" class="input" required>
                            </mat-chip-list>
                            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedCompanyType($event)">
                                <mat-option *ngFor="let companyTypes of filteredCompanyTypes | async"
                                    [value]="companyTypes.id">
                                    {{companyTypes.name}}
                                </mat-option>
                            </mat-autocomplete>
                            <mat-error class="error" *ngIf="(modalFormGroup.get('companyTypes')).hasError('required')">Type is
                                required</mat-error>
                        </mat-form-field>
                        <mat-form-field class="example-chip-list" appearance="standard" *ngIf="type.group == 'people'">
                            <mat-label class="label">Position</mat-label>
                            <mat-chip-list #chipList aria-label="Position select">
                                <mat-chip *ngFor="let position of positions" (removed)="removePosition(position)" class="positionItem">
                                    {{position}}
                                    <button matChipRemove>
                                        <mat-icon>cancel</mat-icon>
                                    </button>
                                </mat-chip>
                                <input placeholder="New Position..." #positionInput matInput [formControl]="position"
                                    [matAutocomplete]="auto" [matChipInputFor]="chipList"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    (matChipInputTokenEnd)="addPosition($event)" class="input">
                            </mat-chip-list>
                            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedPosition($event)">
                                <mat-option *ngFor="let positions of filteredPositions | async" [value]="positions.id">
                                    {{positions.name}}
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                        <mat-form-field class="example-chip-list" appearance="standard" *ngIf="type.group == 'person-key-contact'">
                            <mat-label class="label">Position</mat-label>
                            <mat-chip-list #chipList aria-label="Position select">
                                <mat-chip *ngFor="let position of positions" (removed)="removePosition(position)" class="positionItem">
                                    {{position}}
                                    <button matChipRemove>
                                        <mat-icon>cancel</mat-icon>
                                    </button>
                                </mat-chip>
                                <input placeholder="New Position..." #positionInput matInput [formControl]="position"
                                    [matAutocomplete]="auto" [matChipInputFor]="chipList"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    (matChipInputTokenEnd)="addPosition($event)" class="input">
                            </mat-chip-list>
                            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedPosition($event)">
                                <mat-option *ngFor="let positions of filteredPositions | async" [value]="positions.id">
                                    {{positions.name}}
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                          <!-- Industries -->
                          <mat-form-field class="example-chip-list" appearance="standard" *ngIf="type.group == 'brands'">
                            <mat-label class="label">Industry</mat-label>
                            <mat-chip-list #chipList aria-label="Industry select">
                                <mat-chip *ngFor="let industry of industries" class="industryItem" (removed)="removeIndustry(industry)">
                                    {{industry}}
                                    <button matChipRemove>
                                        <mat-icon>cancel</mat-icon>
                                    </button>
                                </mat-chip>
                                <input placeholder="New Industry..." #industryInput matInput [formControl]="industry"
                                    [matAutocomplete]="auto" [matChipInputFor]="chipList"
                                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                    (matChipInputTokenEnd)="addIndustry($event)" class="input">
                            </mat-chip-list>
                            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedIndustry($event)">
                                <mat-option *ngFor="let industries of filteredIndustries | async" [value]="industries.id">
                                    {{industries.name}}
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                        <mat-form-field class="full-width-input" appearance="standard">
                            <mat-label class="label">Email</mat-label>
                            <input matInput type="text" autocomplete="new-password" class="input"
                                formControlName="email" #emailInput>
                            <mat-error
                                *ngIf="(modalFormGroup.get('email')).hasError('email') && !(modalFormGroup.get('email')).hasError('required')">
                                Please write a valid email</mat-error>
                        </mat-form-field>
                        <mat-form-field class="full-width-input" appearance="standard">
                            <mat-label class="label">Description</mat-label>
                            <textarea matInput class="input" formControlName="description"></textarea>
                        </mat-form-field>
                        <mat-form-field class="full-width-input" appearance="standard">
                            <mat-label class="label">Website</mat-label>
                            <input matInput type="text" autocomplete="new-password" class="input"
                                formControlName="website" #websiteInput>
                            <mat-error *ngIf="(modalFormGroup.get('website')).hasError('pattern') ">Please enter a valid
                                website</mat-error>
                        </mat-form-field>
                        <mat-form-field class="full-width-input" appearance="standard">
                            <mat-label class="label">Phone</mat-label>
                            <input matInput type="text" autocomplete="new-password" class="input"
                                formControlName="phone" #phoneInput>
                        </mat-form-field>
                        <mat-form-field class="full-width-input" appearance="standard">
                            <mat-label class="label">Address</mat-label>
                            <input matInput type="text" autocomplete="new-password" class="input"
                                formControlName="address" #addressInput>
                        </mat-form-field>
                        <mat-select-country appearance="standard" label="Country" class="select_country"
                            [countries]="country" formControlName="country_select" autocomplete="off">
                        </mat-select-country>
                        <input type="hidden" formControlName="country_id">
                    </div>
                    <div class="social-wrapper">
                        <h3>Social networks</h3>
                        <ng-container *ngIf="!showSocialsRow">
                            <div class="show-socials-button f-button font-blue add" (click)="showSocials()">+ Show Socials</div>
                        </ng-container>
                        <div *ngIf="showSocialsRow">
                            <mat-form-field class="full-width-input" appearance="standard">
                                <mat-label class="label">Behance</mat-label>
                                <input matInput type="text" class="input" formControlName="behance" #behanceInput>
                            </mat-form-field>
                            <mat-form-field class="full-width-input" appearance="standard">
                                <mat-label class="label">Facebook</mat-label>
                                <input matInput type="text" class="input" formControlName="facebook" #facebookInput>
                            </mat-form-field>
                            <mat-form-field class="full-width-input" appearance="standard">
                                <mat-label class="label">LinkedIn</mat-label>
                                <input matInput type="text" class="input" formControlName="linkedin" #linkedinInput>
                            </mat-form-field>
                            <mat-form-field class="full-width-input" appearance="standard">
                                <mat-label class="label">Instagram</mat-label>
                                <input matInput type="text" class="input" formControlName="instagram" #instagramInput>
                            </mat-form-field>
                            <mat-form-field class="full-width-input" appearance="standard">
                                <mat-label class="label">Twitter</mat-label>
                                <input matInput type="text" class="input" formControlName="twitter" #twitterInput>
                            </mat-form-field>
                            <mat-form-field class="full-width-input" appearance="standard">
                                <mat-label class="label">Vimeo</mat-label>
                                <input matInput type="text" class="input" formControlName="vimeo" #vimeoInput>
                            </mat-form-field>
                            <mat-form-field class="full-width-input" appearance="standard">
                                <mat-label class="label">Dribbble</mat-label>
                                <input matInput type="text" class="input" formControlName="dribbble" #drebbbleInput>
                            </mat-form-field>
                            <mat-form-field class="full-width-input" appearance="standard">
                                <mat-label class="label">Pinterest</mat-label>
                                <input matInput type="text" class="input" formControlName="pinterest" #pinterestInput>
                            </mat-form-field>
                            <mat-form-field class="full-width-input" appearance="standard">
                                <mat-label class="label">YouTube</mat-label>
                                <input matInput type="text" class="input" formControlName="youtube" #youtubeInput>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>
            <div mat-dialog-actions align="end">
                <button mat-raised-button class="f-sub-header-3" color="primary" type="submit" [disabled]="!modalFormGroup.valid">Update</button>
            </div>
        </form>
    </div>
</div>