<div class="single-person single-section active">
    <div class="person-container single-container">
        <h2 class="f-sub-title-1">Team</h2>
        <div class="people">
            <app-loading-module class="loader" [ngClass]="{'loaded': loadedContent}"></app-loading-module>
            <div class="people-wrapper teams-wrapper" id="people-wrapper" [ngClass]="{'hidden': !loadedContent}">
                <ng-container *ngFor="let person of people; let i = index">
                    <div class="single-person toolperson" *ngIf="i < 15" (click)="toggleRightSidenav(person, $event)" 
                        data-tippy-delay="[350, 350]" [ngxTippy]="tippyTemplate"  [tippyProps]="tippyContent">
                        <button class="delete-button" (click)="deleteRelationship(person.slug, $event)" >
                            <img src="./../../../../../assets/img/off_close.png" alt="delete">
                        </button>
                        <div class="person-logo">
                            <ng-container *ngIf="person.avatar; else noAvatarImg">
                                <cl-image _ngcontent-ywn-2="" public-id="mypic" class="thumbnail inline"
                                    ng-reflect-public-id="mypic">
                                    <img src="{{imageUrl}}/{{ person.avatar }}">
                                </cl-image>
                            </ng-container>
                            <ng-template #noAvatarImg>
                                <img src="./../../../../../assets/img/default-logo.png" alt="avatar">
                            </ng-template>
                        </div>
                        <template #tippyTemplate>
                            <app-tooltip [data]="person" [typePosition]="typeGroup"></app-tooltip>
                        </template>
                        <div class="team-info">
                            <h3 class="name" id="name2">{{ person.name }}</h3>
                            <ng-container>
                                <div  *ngFor="let position of person.positions; let isLast=last; let i = index">
                                    <p>{{position.name }}{{isLast ? ' ' : ', '}}</p>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                    <ng-container *ngIf="i == 15">
                        <div class="single-person toolperson" (click)="toggleRightSidenav(person, $event)" data-tippy-delay="[350, 350]"
                             *ngIf="people.length == 15; else viewAllContent" [ngxTippy]="tippyTemplate"  [tippyProps]="tippyContent">
                            <button class="delete-button" (click)="deleteRelationship(person.slug, $event)" >
                                <img src="./../../../../../assets/img/off_close.png" alt="delete">
                            </button>
                            <div class="person-logo">
                                <cl-image _ngcontent-ywn-2="" public-id="mypic" class="thumbnail inline"
                                    ng-reflect-public-id="mypic">
                                    <img src="{{imageUrl}}/{{ person.avatar }}">
                                </cl-image>
                            </div>
                        </div>                    
                        <ng-template #viewAllContent>
                            <div class="more-people">
                                <div class="view-all-button">
                                    <p class="person-count f-sub-header-2 font-blue"> + <span>{{ dataCount - 15}}</span></p>
                                    <a class="view-all-people" (click)="showAllList()"> <img src="./../../../../../assets/img/view-all.png" alt="View All"></a>
                                </div>
                                <button class="f-button font-blue add" [ngClass]="{'open': openInput, 'view-all-and-add-key': people && dataCount > 15}" (click)="add()">+{{ text.add }}</button> 
                            </div>
                        </ng-template>
                    </ng-container>
                </ng-container>
                <em *ngIf="!people" class="no-people f-body-2">{{ text.noMsg }}</em>
                <div class="person-input" [ngClass]="{open: openInput}">
                    <mat-form-field appearance="standard">
                        <mat-label>{{ text.new }}</mat-label>
                        <input
                            matInput
                            type="text"
                            #inputField
                            #trigger="matAutocompleteTrigger"
                            (keyup.enter)="onInputEnter($event.target.value)"
                            [formControl]="formControl" 
                            [matAutocomplete]="auto"
                            [autofocus]="false">
                        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                            <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                                {{option.name}}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
                <ng-container *ngIf="people?.length < 16">
                    <button class="f-button font-blue add" [ngClass]="{'open': openInput, 'view-all-and-add-key': people && people.length > 15}" (click)="add()">+{{ text.add }}</button> 
                </ng-container>
                <button class="f-button font-blue save" [ngClass]="{'open': openInput}" (click)="submit()">{{ text.save }}</button> 
            </div>
        </div>
    </div>
</div>

<app-sidenav [dataNav]="personClicked" [typePosition]="typeGroup"></app-sidenav>