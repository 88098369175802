import { Injectable } from '@angular/core';
import { Country } from '../interfaces/country';
import { default as CountriesData } from './../shared/countries.json';
import { MatDialog, MatDialogConfig, _closeDialogVia } from '@angular/material/dialog';
import { BehaviorSubject, Subject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class OpendialogService {
    countryDb: Country[];
    public modalData: Subject<any> = new Subject();
    public updateModalData: Subject<any> = new Subject();
    public reviewData: Subject<any> = new Subject();
    public clientData: Subject<any> = new Subject();
    public closeModal: Subject<boolean> = new Subject<boolean>();
    public deletePerson: Subject<any> = new Subject();
    public deleteBrand: Subject<any> = new Subject();
    public deleteService: Subject<any> = new Subject();
    public deleteIndustry: Subject<any> = new Subject();
    public deleteCompany: Subject<any> = new Subject();
    public deleteCompanyType: Subject<any> = new Subject();
    public deleteCompanyTypeItem: Subject<any> = new Subject();
    public updateCompanyTypeItem: Subject<any> = new Subject();
    
    constructor(
        public dialog: MatDialog) {
        this.countryDb = CountriesData;
    }

    openModal(type, componentDialog, ssData=null, positions=[], allCompanyTypes=[], industries=[]){
        let customData;
        if(ssData){
            customData = {
                name: ssData.name,
                person: ssData.person,
                person_id: ssData.person_id,
                brand_id: ssData.brand_id,
                company_id: ssData.company_id,
                collaborator_id: ssData.collaborator_id,
                companyTypes: ssData.companyTypes
            }
        }

        this.dialog.open(componentDialog,{
            width: '770px', 
            panelClass: 'add-modalbox',
            data: {countries: this.countryDb,type: type, ssData:customData, positions: positions, allCompanyTypes: allCompanyTypes, industries: industries},
            autoFocus: false, 
            restoreFocus: false
        });
    }

    openUpdateModal(type, componentDialog, ssData=null, positions=[], allCompanyTypes=[], allSingleData=null, industries=[]){
        let customData;
        if(ssData){
            customData = {
                name: ssData.name,
                person: ssData.person,
                person_id: ssData.person_id,
                brand_id: ssData.brand_id,
                company_id: ssData.company_id,
                collaborator_id: ssData.collaborator_id,
                companyTypes: ssData.companyTypes
            }
        }

        this.dialog.open(componentDialog,{
            width: '770px', 
            panelClass: 'edit-modalbox',
            data: {countries: this.countryDb,type: type, ssData:customData, positions: positions, allCompanyTypes: allCompanyTypes, allSingleData: allSingleData, industries: industries},
            autoFocus: false, 
            restoreFocus: false
        });

    }

    openAllListModal(type, componentDialog, allData){
        this.dialog.open(componentDialog,{
            width: '770px', 
            panelClass: 'all-list-modalbox',
            data: {countries: this.countryDb,type: type, allData},
            autoFocus: true,
        });
    }

    openReviewDialog(type, componentDialog, rate, prevRate, singleModalData){
        this.dialog.open(componentDialog,{
            panelClass: 'small-modalbox',
            data: {type: type, rate, prevRate, singleModalData: singleModalData},
            autoFocus: true,
        });
    }

    openClientDialog(type, componentDialog, isClient, singleModalData){
        this.dialog.open(componentDialog,{
            panelClass: 'small-modalbox',
            data: {type: type, isClient: isClient, singleModalData: singleModalData},
            autoFocus: true,
        });
    }

    openDeleteDialog(type, componentDialog, slug, companyType = null){
        this.dialog.open(componentDialog,{
            panelClass: 'small-modalbox',
            data: {type: type, slug: slug, companyType: companyType },
            autoFocus: true,
        });
    }
}
