// export class Config {
//     static main = '.localhost:4200/'
//     static url = 'http://localhost:8000/api/';
//     static assetsUrl = 'http://localhost:8000/images/';
//     static http = 'http://';
//     static suburl = '.localhost:8000/api/';
//     static baseurl = 'http://localhost:4200/'
// }

export class Config {
    static main = '.f-network.staaging.com/'
    static url = 'https://b-network.staaging.com/api/';
    static assetsUrl = 'https://b-network.staaging.com/images/';
    static http = 'http://';
    static suburl = '.b-network.staaging.com/api/';
    static baseurl = 'http://f-network.staaging.com/'
}
