import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NotifierService } from 'angular-notifier';
import { Company } from 'src/app/interfaces/company';
import { CompanyTypesService } from 'src/app/services/company-types.service';
import { CompanyService } from 'src/app/services/company.service';
import { OpendialogService } from 'src/app/services/opendialog.service';
import { CompaniesText, CompanyTypesText, NotificationText } from '../../lang/globals-en';
import { AddDialogComponent } from '../dialogs/add-dialog/add-dialog.component';
import { SmallDialogComponent } from '../dialogs/small-dialog/small-dialog.component';
import { SeoService } from 'src/app/services/seo.service';
import { Subscription } from 'rxjs';
import { SingleDialogComponent } from '../dialogs/single-dialog/single-dialog.component';

@Component({
    selector: 'app-companies',
    templateUrl: './companies.component.html',
    styleUrls: ['./companies.component.scss']
})
export class CompaniesComponent implements OnInit {
    companies: Company[];
    companiesNotification = CompaniesText;
    companyTypeNotification = CompanyTypesText;
    error: null;
    errorStatus: null;
    private subscribtion: Subscription;
    private deleteSubscribtion: Subscription;
    private updateSubscribtion: Subscription;
    allCompanyTypes: any;
    anyParam: any[];

    constructor(
        private openDialogService: OpendialogService,
        private companyService: CompanyService,
        private notifier: NotifierService,
        private companyTypeService: CompanyTypesService,
        private seoService: SeoService) {

            this.seoService.setSeoMetaTags({title: 'Companies'});

            this.subscribtion = this.openDialogService.modalData.subscribe((res:any) => {
                if (res.type == 'company') {
                    this.submit(res.data);
                }
                if (res.type == 'type') {
                    this.submitTypes(res.data);
                }
            })
            this.updateSubscribtion = this.companyService.reloadCompanyTable.subscribe((res:any) => {
                this.getCompanies();
            });
            this.deleteListen();
        }
        

    ngOnInit(): void {
        this.getCompanies();
        this.getCompanyTypes();
    }

    ngOnDestroy(): void {
        if(this.subscribtion){
            this.subscribtion.unsubscribe();
        }
        if(this.deleteSubscribtion){
            this.deleteSubscribtion.unsubscribe();
        }
        if(this.updateSubscribtion){
            this.updateSubscribtion.unsubscribe();
        }
    }

    deleteListen(){
        this.deleteSubscribtion = this.openDialogService.deleteCompany.subscribe((res: any) => {
            this.delete(res)
        })
    }

    getCompanies() {
        this.companyService.index().subscribe((res: any) => {
            this.companies = res.data
        })
    }

    submit(data): void {
        for (let i = 0; i < this.allCompanyTypes.length; i++) {
            if(data.companyTypes.includes(this.allCompanyTypes[i].id)){
                this.companyService.refreshJsonCompaniesCache(this.allCompanyTypes[i].slug)
            }
        }
       
        this.companyService.store(data).subscribe(
            data => this.handleResponse(data),
            error => this.handleError(error, data),
        );
    }

    handleResponse(data) {
        this.notifier.notify('success', this.companiesNotification.added);
        this.getCompanies();
        this.deleteSubscribtion.unsubscribe()
        this.deleteListen();
    }

    handleError(error, data) {
        this.companyService.deleteError(data).subscribe();
        this.error = error.error.message;
        this.errorStatus = error.status;
        this.notifier.notify('error', this.companiesNotification.add_err);
    }

    submitTypes(data){
        this.companyTypeService.store(data).subscribe(
            data => this.handleTypesResponse(data),
            error => this.handleTypesError(error, data),
        )
    }

    handleTypesResponse(data) {
        this.companyTypeService.refreshCompanyTypesCache();
        this.companyTypeService.refreshCompanyType.next(true);
        this.notifier.notify('success', this.companyTypeNotification.added);
        this.openDialogService.closeModal.next(true);
        this.openDialogService.closeModal.next(false);
        this.getCompanyTypes();
    }

    handleTypesError(error, data) {
        this.openDialogService.closeModal.next(true);
        this.openDialogService.closeModal.next(false);
        this.companyTypeService.deleteError(data).subscribe();
        this.notifier.notify('error', this.companyTypeNotification.add_err);
    }

    getCompanyTypes() {
        this.companyTypeService.index().subscribe((res: any) => {
            this.allCompanyTypes = res.data;
        })
    }

    openCompanyDialog() {
        this.openDialogService.openModal('company', AddDialogComponent, '',this.anyParam, this.allCompanyTypes);
    }

    openTypeDialog() {
        this.openDialogService.openModal('type', SingleDialogComponent);
    }

    openDelete(slug, types) {
        let selTypes = []
        for (let i = 0; i < types.length; i++) {
            selTypes.push(types[i].plural.toLowerCase())
        }
        this.openDialogService.openDeleteDialog('company', SmallDialogComponent, slug, selTypes);
    }

    delete(data){
        for (let i = 0; i < data.companyType.length; i++) {
            this.companyTypeService.refreshTypeCache(data.companyType[i])
            this.companyService.refreshRelationsByTypeCache(data.companyType[i])
        }

        this.companyService.delete(data.slug).subscribe(
            data => this.handleDeleteResponse(data),
            error => this.handleDeleteError(error),
        )
    }

    handleDeleteResponse(data) {
        this.notifier.notify('success', this.companiesNotification.removed);
        this.getCompanies();
    }

    handleDeleteError(error) {
        this.notifier.notify('error', this.companiesNotification.remove_err);
        this.error = error.error.message;
        this.errorStatus = error.status;
    }
}
