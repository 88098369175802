import { Component, Inject, Input, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NgxTippyProps } from 'ngx-tippy-wrapper';
import { SidenavService } from 'src/app/services/sidenav.service';
import { ImageUrl } from 'src/app/components/lang/globals-en';


@Component({
  selector: 'app-all-list-dialog',
  templateUrl: './all-list-dialog.component.html',
  styleUrls: ['./all-list-dialog.component.scss']
})
export class AllListDialogComponent implements OnInit {
    country;
    type;
    @Input() tooltipdata: any;
    allData = [];
    itemClicked: any;
    imageUrl = ImageUrl;
    imageUrlType;
    typePosition;
    typeGroup;
    allDataKeyContact;

    tippyContent: NgxTippyProps = {
        allowHTML: true,
        interactive: true,
    };

  constructor(
    @Optional() public dialogRef: MatDialogRef<AllListDialogComponent>,
    private sidenavService: SidenavService,
    @Inject(MAT_DIALOG_DATA) public data) { 
        this.country = this.data.countries;
        this.type = this.data.type;
        this.allData = this.data.allData;
        this.typePosition = this.type.group;
        
        if(this.typePosition == 'brands'){
            this.imageUrlType = this.imageUrl.brands;
            this.typeGroup = this.type;
        }else if(this.typePosition == 'people') {
          this.imageUrlType = this.imageUrl.people;
          this.typeGroup = this.type;
        }
        else if(this.typePosition == 'person-key-contact'){
          this.imageUrlType = this.imageUrl.people;
          this.typeGroup = this.type;
          const keyPersonDatas: any = [];
          this.data.allData.forEach((element, index) => {
              keyPersonDatas[index] = element.person
          });
          this.allData = keyPersonDatas;
          
        }else{
            this.imageUrlType = this.imageUrl.company;
            this.typeGroup = this.type;
        }
    }

  ngOnInit(): void {
  }

  openRightSidenav(item, event) {
    event.stopPropagation();
    this.itemClicked = item;
    this.sidenavService.open();
    this.sidenavService.sidenavData.next({data: item, type: this.typeGroup});
  }

}
