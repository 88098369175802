import { AfterViewInit, Component, ElementRef, HostListener, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { NotifierService } from 'angular-notifier';
import { NgxTippyProps } from 'ngx-tippy-wrapper';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { CompaniesText, ImageUrl, NotificationText } from 'src/app/components/lang/globals-en';
import { CompanyService } from 'src/app/services/company.service';
import { OpendialogService } from 'src/app/services/opendialog.service';
import { SidenavService } from 'src/app/services/sidenav.service';
import { AddDialogComponent } from '../../dialogs/add-dialog/add-dialog.component';
import { AllListDialogComponent } from '../../dialogs/all-list-dialog/all-list-dialog.component';
import { BrandService } from 'src/app/services/brand.service';
import { GeneralService } from 'src/app/services/general.service';
import { PersonService } from 'src/app/services/person.service';

@Component({
    selector: 'app-ss-companies',
    templateUrl: './ss-companies.component.html',
    styleUrls: ['./ss-companies.component.scss']
})
export class SsCompaniesComponent implements OnInit, OnDestroy, AfterViewInit {
    @Input() typeData;
    @Input() type;
    @Input() companyType;
    notifications = NotificationText;
    companyNotifications = CompaniesText;
    formControl = new FormControl();
    storeData = {
        name: null,
        slug: null,
        brand_id: null,
        person_id: null,
        company_id: null,
        companyTypes: null,
        exists: false,
    };
    options;
    filteredOptions: Observable<any>;
    companies = [];
    openInput:boolean = false;
    private subscribtion:any;
    private storeSubscribtion:any;
    private updateSubscribtion:any;
    private updateModalSubscribtion:any;
    imageUrl = ImageUrl.companyIcon;
    isBrandClicked:boolean;
    brandClicked: any;
    typeGroup = {
        group: 'company',
        type: null
    };
    selectedListItem: any;
    loadedContent:boolean = false;
    tippyContent: NgxTippyProps = {
        allowHTML: true,
        interactive: true,
    };
    dataCount;
    isEmailGmail;

    @ViewChild(MatAutocompleteTrigger) autocomplete: MatAutocompleteTrigger;
    @ViewChild("inputField") inputField: ElementRef;

    constructor(
        private companyService: CompanyService,
        private notifier: NotifierService,
        private openDialogService: OpendialogService,
        private sidenavService: SidenavService,
        private brandService: BrandService,
        private personService: PersonService,
        private generalService: GeneralService
    ) {  
        this.subscribtion = this.openDialogService.modalData.subscribe((res:any) => {
            if (res.type == 'company' && res.data.companyTypes == this.companyType.name) {
                this.submit(res.data);
            }
        })
               
        this.updateModalSubscribtion = this.openDialogService.updateModalData.subscribe((res: any) => {
            if (res.type.type.name == this.companyType.name && res.type.group == "company") {
                this.updateCompany(res.slug, res.data)
            }
        })
    }

    @HostListener('window:keyup.esc') onKeyUp() {
        this.resetInputForm();
    }

    ngOnInit(): void {
        if(this.type == 'brands'){
            this.storeData.brand_id = this.typeData.id;
        } else if (this.type == 'people'){
            this.storeData.person_id = this.typeData.id
        } else {
            this.storeData.company_id = this.typeData.id
        }
        this.storeData.companyTypes = [this.companyType.name]
        this.sidenavService.cast.subscribe(data => this.isBrandClicked = data);
 
    }

    ngOnDestroy(): void {
        if(this.subscribtion){
            this.subscribtion.unsubscribe();
        }
        if (this.storeSubscribtion) {
            this.storeSubscribtion.unsubscribe();
        }
        if (this.updateSubscribtion) {
            this.updateSubscribtion.unsubscribe();
        }
        if (this.updateModalSubscribtion) {
            this.updateModalSubscribtion.unsubscribe();
        }
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.getCompanies();
        }, 0);
    //    this.listItems();
    }

    listItems(){
        this.companyService.getListByType(this.companyType.slug).subscribe((res:any) => {
            if(this.selectedListItem){
                this.options = res.filter(ar => !this.selectedListItem.find(rm => (rm.slug === ar.slug) ));
            }else{
                this.options = res;
            }
            this.filterOptions();
        })
    }

    getCompanies(){
        this.companyService.getRelationshipsLimitData(this.type, this.typeData.slug, this.companyType.slug).subscribe((res:any) => {
            this.typeGroup.type = this.companyType
            this.dataCount = res.allDataCount;
            this.companies = res.data;
            setTimeout(() => {
                this.loadedContent = true;
            }, 100);
        })
    }

    filterOptions(){
        this.filteredOptions = this.formControl.valueChanges.pipe(
            startWith(''),
            map(value => (typeof value === 'string' ? value : value?.name)),
            map(name => (name ? this._filter(name) : this.options.slice())),
        );
    }

    displayFn(company): string {
        return company && company.name ? company.name : '';
    }
    
    private _filter(name: string) {
        const filterValue = name.toLowerCase();
        return this.options.filter(option => option.name.toLowerCase().includes(filterValue));
    }

    onInputEnter(e){
        if(this.formControl.value){
            this.storeData.name = this.formControl.value.slug ? this.formControl.value.slug : this.formControl.value;
            if(this.formControl.value.slug){
                this.submit();
            } else {
                this.openDialogService.openModal('company', AddDialogComponent, this.storeData);
                this.resetInputForm()
            }
        }
    }

    submit(data=null){
        let submitData;
        if(data){
            submitData = data;
        } else {
            this.storeData.name = this.formControl.value.name ? this.formControl.value.name : this.formControl.value;
            if(this.formControl.value.name){
                this.storeData.exists = true;
                this.storeData.slug = this.formControl.value.slug;
            } else {
                this.storeData.exists = false;
                this.storeData.slug = null;
            }
            submitData = this.storeData
        }
        
        if(submitData){
            const nameData = {
                name: submitData.name
            }
            this.storeSubscribtion = this.companyService.store(submitData).subscribe(
                data => this.handleResponse(data),
                error => this.handleError(error, nameData),
            );
        } else {
            setTimeout(() => {
                this.inputField?.nativeElement.focus();
            }, 200);
        }
    }

    updateCompany(id, data){
        this.companyService.refreshRelCompaniesCache(this.companyType.slug);
        this.updateSubscribtion = this.companyService.update(id, data).subscribe(
            data => this.handleUpdateResponse(data),
            error => this.handleUpdateError(error, data),
        );
    }

    handleResponse(data) {
        console.log(this.companyType.slug);
        
        this.companyService.refreshRelCompaniesCache(this.companyType.slug);
        this.notifier.notify('success', this.notifications.relationship);
        this.getCompanies();
        this.listItems();
        this.resetInputForm();
        this.storeSubscribtion.unsubscribe();

        const dataSlug = data.slug;

        if(this.type == 'people'){
            this.personService.refreshRelPeopleCache(false);
        }
        if(this.type == 'brands'){
            this.brandService.refreshRelBrandsCache();
        }
        if(this.type == 'companies'){
            const typesOfCompanies = this.typeData.types[0].slug;
            this.companyService.refreshRelCompaniesCache(typesOfCompanies);
        }
    }

    handleError(error, data) {
        this.companyService.deleteError(data).subscribe();
        this.notifier.notify('error', this.notifications.relationship_err);
        this.storeSubscribtion.unsubscribe();
    }

    handleUpdateResponse(data) {
        this.notifier.notify('success', this.companyNotifications.updated);
        this.getCompanies();
        this.listItems();
        this.companyService.refreshCompanyCache(data.data.slug);
        this.updateSubscribtion.unsubscribe();
    }

    handleUpdateError(error, data) {
        this.notifier.notify('error', this.companyNotifications.update_err);
        this.updateSubscribtion.unsubscribe();
    }

    add(){
        if(this.companies?.length === 16){
            this.companyService.getRelationships(this.type, this.typeData.slug, this.companyType.slug).subscribe((res:any) => {
                this.selectedListItem = res.data
                this.openInput = true;
                setTimeout(() => {
                    this.inputField?.nativeElement.focus()
                }, 200);
                this.listItems();
            });
        } else {
            this.selectedListItem = this.companies
            this.openInput = true;
            setTimeout(() => {
                this.inputField?.nativeElement.focus()
            }, 200);
            this.listItems();
        }
    }

    resetInputForm(){
        this.openInput = false;
        this.storeData.name = '';
        this.autocomplete.closePanel();
        this.formControl.setValue(null);
    }

    showAllList(){
        let typeGroupDynamic = {
            group: 'company',
            type: {
                name: this.companyType.name,
                plural: this.companyType.plural
            }
        }
        this.companyService.getRelationships(this.type, this.typeData.slug, this.companyType.slug).subscribe((res:any) => {  
            this.openDialogService.openAllListModal(typeGroupDynamic, AllListDialogComponent, res.data);
        });
    }

    toggleRightSidenav(company, event) {
        event.stopPropagation();
        let typeGroupDynamic = {
            group: 'company',
            type: {
                name: this.companyType.name,
                plural: this.companyType.plural
            }
        }

        const emailData = {
            email: company.email
        }

        if(company.email){
            this.generalService.checkEmailDns(emailData).subscribe((res: any) => {
                this.isEmailGmail = res;
                this.sidenavService.sidenavData.next({data: company, type: typeGroupDynamic, isGmail: this.isEmailGmail});
                this.sidenavService.open();
            })
        }else{
            this.isEmailGmail = false;
            this.sidenavService.sidenavData.next({data: company, type: typeGroupDynamic, isGmail: this.isEmailGmail});
            this.sidenavService.open();
        }
    }

    deleteRelationship(slug, event){
        event.stopPropagation();
        this.companyService.refreshRelCompaniesCache(this.companyType.slug);
        const typesOfCompanies = this.typeData.types[0].slug;
        this.companyService.refreshRelCompaniesCache(typesOfCompanies);
        
        this.companyService.removeRelationship(slug, this.type, this.typeData.id).subscribe(
            data => this.handleDeleteRelationshipResponse(data),
            error => this.handleDeleteRelationshipError(error),
        )
    }
    
    handleDeleteRelationshipResponse(data){
        this.notifier.notify('success', this.notifications.relationshipDel);
        this.getCompanies();
        this.listItems();
    }
    
    handleDeleteRelationshipError(error){
        this.notifier.notify('error', this.notifications.relationshipDel_err);
    }
}
