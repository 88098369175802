import { CommonModule } from "@angular/common";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { FormControl } from "@angular/forms";
import { SsServicesComponent } from "./ss-services/ss-services.component";
import { NgMaterialModule } from 'src/app/ng-material/ng-material.module';
import { MatSelectCountryModule } from '@angular-material-extensions/select-country';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { SsIndustriesComponent } from "./ss-industries/ss-industries.component";
import { MatChipInputEvent } from '@angular/material/chips';
import { SsPeopleComponent } from "./ss-people/ss-people.component";
import { SsBrandsComponent } from "./ss-brands/ss-brands.component";
import { NgxTippyProps } from 'ngx-tippy-wrapper';
import { SsCompaniesComponent } from "./ss-companies/ss-companies.component";
import { TooltipModule } from "../tooltip/tooltip.module";
import { SidenavModule } from "../dialogs/sidenav/sidenav.module";
import { LoadingModule } from "../loading-module/loading-module.module";
import { SsKeyContactsComponent } from "./ss-key-contacts/ss-key-contacts.component";
import { AutofocusDirective } from "src/app/directives/autofocus.directive";
import { SsCollaboratorsComponent } from './ss-collaborators/ss-collaborators.component';
import { SsPositionsComponent } from './ss-positions/ss-positions.component';
import { SsHeaderComponent } from './ss-header/ss-header.component';
import { BarRatingModule } from "ngx-bar-rating";
import { SsSkillsComponent } from './ss-skills/ss-skills.component';


@NgModule({
    declarations: [
        SsServicesComponent,
        SsIndustriesComponent,
        SsPeopleComponent,
        SsBrandsComponent,
        SsCompaniesComponent,
        SsKeyContactsComponent,
        AutofocusDirective,
        SsCollaboratorsComponent,
        SsPositionsComponent,
        SsHeaderComponent,
        SsSkillsComponent
    ],
    imports: [
        CommonModule,
        NgMaterialModule,
        MatSelectCountryModule,
        HttpClientModule,
        ReactiveFormsModule,
        TooltipModule,
        SidenavModule,
        LoadingModule,
        BarRatingModule
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ],

    exports: [
        SsServicesComponent,
        SsIndustriesComponent,
        SsPeopleComponent,
        SsBrandsComponent,
        TooltipModule,
        SsCompaniesComponent,
        SidenavModule,
        SsKeyContactsComponent,
        AutofocusDirective,
        SsCollaboratorsComponent,
        SsPositionsComponent,
        SsHeaderComponent,
        SsSkillsComponent
    ]

})


export class SingleSharedModule{ 
}