import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { AuthService } from 'src/app/services/auth/auth.service';
import { TokenService } from 'src/app/services/auth/token.service';
import { LoginText, NotificationText } from '../../lang/globals-en';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    notifications = NotificationText;
    text = LoginText;
    form: FormGroup;
    headerText:any = this.text.header;
    hide = true;
    error: null;
    errorStatus: null;

    constructor(private authService: AuthService,
                private tokenService: TokenService,
                private router: Router,
                private fb: FormBuilder,
                private notifier: NotifierService) {
    }

    ngOnInit(): void {
        this.form = this.fb.group({
            email: [null, [Validators.required, Validators.email]],
            password: [null, [Validators.required, Validators.minLength(8)]],
            logged_in: [null, null],
            positions: [null, null]
        });
    }

    onSubmit() {
        this.error = null;
        this.errorStatus = null;
        
        return this.authService.login(this.form.value).subscribe(
            data => this.handleResponse(data),
            error => this.handleError(error)
        );
    }

    handleResponse(data) {
        this.notifier.notify('success', this.notifications.logged_in);
        this.tokenService.handle(data.token);
        this.router.navigateByUrl('/dashboard');
    }

    handleError(error) {
        this.notifier.notify('error', this.notifications.logged_in_error);
        this.headerText = this.text.header_err;
        this.error = error.error.message;
        this.errorStatus = error.status;
    }

}
