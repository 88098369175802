import { Injectable } from '@angular/core';
import { Config } from '../config';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TokenService } from './auth/token.service';
import { CacheResolverService } from './cache-resolver.service';

@Injectable({
    providedIn: 'root'
})
export class SkillService {

    config:Config;
    constructor(
        private http: HttpClient,
        private token: TokenService,
        private cacheResolver: CacheResolverService) { }

    headers = new HttpHeaders({'Content-Type': 'application/json', 'Authorization':`Bearer ${this.token.get()}`});

    index(){
        return this.http.get(Config.url + 'skills', {headers:this.headers});
    }
    
    getList(){
        return this.http.get(Config.url + 'skills/getJson/list', {headers:this.headers});
    }

    getRelationships(type, slug){
        return this.http.get(Config.url + type + '/' + slug + '/relationships/skills', {headers:this.headers});
    }
    
    store(data){
        return this.http.post(Config.url + 'skills', data, {headers:this.headers});
    }
    
    deleteError(data){
        return this.http.post(Config.url + 'skills/data/error', data, {headers:this.headers}); 
    }
    
    deleteRelationship(slug, data){
        return this.http.post(Config.url + 'skills/'+ slug +'/deleteRelationship', data, {headers:this.headers});
    }

    refreshRelSkillsCache(type, slug){
        this.cacheResolver.delete(Config.url + type + '/' + slug + '/relationships/skills')
        this.refreshJsonSkillsCache();
    }

    refreshJsonSkillsCache(){
        this.cacheResolver.delete(Config.url + 'skills/getJson/list')
        this.cacheResolver.delete(Config.url + 'skills')
    }

}
