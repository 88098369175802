import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Config } from '../config';
import { TokenService } from './auth/token.service';
import { CacheResolverService } from './cache-resolver.service';
import { PositionService } from './position.service';

@Injectable({
  providedIn: 'root'
})
export class PersonService {
    config:Config;
    public reloadPeople: Subject<any> = new Subject();
    public reloadPersonPositions: Subject<any> = new Subject();

    constructor(private http: HttpClient, 
        private token: TokenService,
        private cacheResolver: CacheResolverService,
        private positionService: PositionService) { }

    headers = new HttpHeaders({'Content-Type': 'application/json', 'Authorization':`Bearer ${this.token.get()}`});

    index(){
        return this.http.get(Config.url + 'people', {headers:this.headers});
    }

    getList(){
        return this.http.get(Config.url + 'people/getJson/list', {headers:this.headers});
    }

    store(data){
        return this.http.post(Config.url + 'people', data, {headers:this.headers});
    }

    show(slug){
        return this.http.get(Config.url + 'people/' + slug, {headers:this.headers});
    }

    isClient(slug){
        return this.http.get(Config.url + 'people/' + slug + '/getJson/client', {headers:this.headers});
    }

    removeClient(slug){
        return this.http.delete(Config.url + 'people/' + slug + '/getJson/client', {headers:this.headers});
    }

    getRelationships(type, slug){
        return this.http.get(Config.url + type + '/' + slug + '/relationships/people', {headers:this.headers});
    }

    getRelationshipsAllData(type, slug){
        return this.http.get(Config.url + type + '/' + slug + '/relationships/people/alldata', {headers:this.headers});
    }

    getRelationshipsLimitData(type, slug){
        return this.http.get(Config.url + type + '/' + slug + '/relationships/people/limitData', {headers:this.headers});
    }

    getRelationshipsSingleData(type, person, params, slug){
        return this.http.get(Config.url + type + '/' + person + '/relationships/' + params + '/' + slug + '/singleData', {headers:this.headers});
    }

    update(slug, data){
        return this.http.put(Config.url + 'people/' + slug, data, {headers:this.headers}); 
    }

    delete(slug){
        return this.http.delete(Config.url + 'people/' + slug, {headers:this.headers}); 
    }

    deleteError(data){
        return this.http.post(Config.url + 'people/data/error', data, {headers:this.headers}); 
    }
    
    removeRelationship(slug, params, id){
        return this.http.post(Config.url + 'people/' + slug + '/relationships/' + params + '/detach/' + id, params, {headers:this.headers});
    }

    refreshRelPeopleCache(positionsChanged){
        // this.cacheResolver.delete(Config.url + type + '/' + slug + '/relationships/people/limitData')
        // this.cacheResolver.delete(Config.url + type + '/' + slug + '/relationships/people/alldata')
        this.refreshRelationsCache()
        this.refreshKeyContactsCache()
        this.refreshJsonPeopleCache()
        if(positionsChanged){
            this.positionService.refreshJsonPositionsCache();
            this.positionService.refreshAllRelPositionsCache();
        }
    }
    
    refreshJsonPeopleCache(){
        this.cacheResolver.delete(Config.url + 'people/getJson/list');
        this.cacheResolver.delete(Config.url + 'people');
    }

    refreshClientCache(slug){
        this.cacheResolver.delete(Config.url + 'people/' + slug + '/getJson/client')
    }
        
    refreshPersonCache(slug){
        this.cacheResolver.delete(Config.url + 'people/' + slug)
    }

    refreshRelationsCache(){
        const cacheItems = { ...localStorage };
        const keysArray = Object.keys(cacheItems);
        for (let i = 0; i < keysArray.length; i++) {
            if(keysArray[i].includes('/relationships/people')){
                this.cacheResolver.delete(keysArray[i])
            }
        }
    }

    refreshKeyContactsCache(){
        const cacheItems = { ...localStorage };
        const keysArray = Object.keys(cacheItems);
        for (let i = 0; i < keysArray.length; i++) {
            if(keysArray[i].includes('/key_contacts/getJson/')){
                this.cacheResolver.delete(keysArray[i])
            }
        }
    }
}
