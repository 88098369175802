import { AfterViewInit, Component, ElementRef, HostListener, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { NotifierService } from 'angular-notifier';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { BrandsText, ImageUrl, NotificationText } from 'src/app/components/lang/globals-en';
import { BrandService } from 'src/app/services/brand.service';
import { OpendialogService } from 'src/app/services/opendialog.service';
import { AddDialogComponent } from '../../dialogs/add-dialog/add-dialog.component';
import { NgxTippyProps } from 'ngx-tippy-wrapper';
import { AllListDialogComponent } from '../../dialogs/all-list-dialog/all-list-dialog.component';
import { SidenavService } from 'src/app/services/sidenav.service';
import { IndustryService } from 'src/app/services/industry.service';
import { ActivatedRoute } from '@angular/router';
import { PersonService } from 'src/app/services/person.service';
import { CompanyService } from 'src/app/services/company.service';
import { GeneralService } from 'src/app/services/general.service';
import { take } from 'rxjs/operators';

@Component({
    selector: 'app-ss-brands',
    templateUrl: './ss-brands.component.html',
    styleUrls: ['./ss-brands.component.scss']
})

export class SsBrandsComponent implements OnInit, OnDestroy, AfterViewInit {
    @Input() typeData;
    @Input() type;
    notifications = NotificationText;
    brandNotifications = BrandsText;
    imageUrl = ImageUrl.brandsIcon;

    formControl = new FormControl();
    storeData = {
        name: null,
        slug: null,
        person_id: null,
        company_id: null,
        exists: false,
    };
    options;
    filteredOptions: Observable<any>;
    brands = [];
    openInput: boolean = false;
    private subscribtion: any;
    private storeSubscribtion: any;
    private updateSubscribtion: any;
    private updateModalSubscribtion: any;
    typeGroup = {
        group: 'brands',
        type: {
            name: 'Brand',
            plural: "brands"
        }
    };

    tippyContent: NgxTippyProps = {
        allowHTML: true,
        interactive: true,
    };

    selectedListItem: any;
    loadedContent: boolean = false;

    @ViewChild(MatAutocompleteTrigger) autocomplete: MatAutocompleteTrigger;
    @ViewChild("inputField") inputField: ElementRef;

    isBrandClicked:boolean;
    brandClicked: any;
    typePosition;
    routeActive: string;
    dataCount;
    allIndustries;
    isEmailGmail;

    constructor(private brandService: BrandService,
        private notifier: NotifierService,
        private openDialogService: OpendialogService,
        private sidenavService: SidenavService,
        private industryService: IndustryService,
        private route: ActivatedRoute,
        private personService: PersonService,
        private companyService: CompanyService,
        private generalService: GeneralService) {

        this.subscribtion = this.openDialogService.modalData.subscribe((res: any) => {
            if (res.type == 'brand') {
                this.submit(res.data);
            }
        })

        this.updateModalSubscribtion = this.openDialogService.updateModalData.subscribe((res: any) => {
            if(res.type.group == 'brands'){
                this.updateBrand(res.slug, res.data)
            }
        })
    }

    @HostListener('window:keyup.esc') onKeyUp() {
        this.resetInputForm();
    }

    ngOnInit(): void {
        if(this.type == 'people'){
            this.storeData.person_id = this.typeData.id;
        } else {
            this.storeData.company_id = this.typeData.id;
        }
        this.sidenavService.cast.subscribe(data => this.isBrandClicked = data);
        this.routeActive = this.route.snapshot.params.slug;

        this.industryService.reloadIndustries.subscribe((res: any) => {
            if(res){
                setTimeout(() => {
                    this.allIndustriesList();
                }, 100);
            }
        })
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.getBrands();
        }, 0);
        this.allIndustriesList()
        // this.listItems();
    }

    listItems(){
        this.brandService.getList().subscribe((res: any) => {
            if(this.selectedListItem){
                this.options = res.data.filter(ar => !this.selectedListItem.find(rm => (rm.slug === ar.slug) ));
            }else{
                this.options = res.data;
            }
            this.filterOptions();
        })
    }
    allIndustriesList(){
        this.industryService.index().subscribe((res: any) => {
            this.allIndustries = res.data;
        })
    }

    ngOnDestroy(): void {
        if (this.subscribtion) {
            this.subscribtion.unsubscribe();
        }
        if (this.storeSubscribtion) {
            this.storeSubscribtion.unsubscribe();
        }
        if (this.updateSubscribtion) {
            this.updateSubscribtion.unsubscribe();
        }
        if (this.updateModalSubscribtion) {
            this.updateModalSubscribtion.unsubscribe();
        }
    }

    getBrands(){
        this.brandService.getRelationshipsLimitData(this.type, this.typeData.slug).subscribe((res:any) => {
            this.dataCount = res.allDataCount;
            this.brands = res.data;
            if(this.brands){
                for (let i = 0; i < this.brands.length; i++) {
                    if(this.type == 'people'){
                        if(res.data){
                            this.brands[i].person_id = this.typeData.id;
                        }
                    } else {
                        if(res.data){
                            this.brands[i].company_id = this.typeData.id;
                        }
                    }
                }
            }

            setTimeout(() => {
                this.loadedContent = true;
            }, 100);
        })
    }

    filterOptions() {
        this.filteredOptions = this.formControl.valueChanges.pipe(
            startWith(''),
            map(value => (typeof value === 'string' ? value : value?.name)),
            map(name => (name ? this._filter(name) : this.options.slice())),
        );
    }

    displayFn(brand): string {
        return brand && brand.name ? brand.name : '';
    }

    private _filter(name: string) {
        const filterValue = name.toLowerCase();
        return this.options.filter(option => option.name.toLowerCase().includes(filterValue));
    }

    onInputEnter(e) {
        if (this.formControl.value) {
            this.storeData.name = this.formControl.value.slug ? this.formControl.value.slug : this.formControl.value;
            if (this.formControl.value.slug) {
                this.submit();
            } else {                
                this.openDialogService.openModal('brand', AddDialogComponent, this.storeData, [], [], this.allIndustries);
                this.resetInputForm()
            }
        }
    }

    showAllList(){
        this.brandService.getRelationshipsAllData(this.type, this.typeData.slug).subscribe((res:any) => {
            this.openDialogService.openAllListModal(this.typeGroup, AllListDialogComponent, res.data);
        });
    }

    toggleRightSidenav(brand) {
        event.stopPropagation();
        const emailData = {
            email: brand.email
        }
        if(brand.email){
            this.generalService.checkEmailDns(emailData).subscribe((res: any) => {
                this.isEmailGmail = res;
                this.sidenavService.sidenavData.next({data:  brand, type: this.typeGroup, isGmail: this.isEmailGmail});
                this.sidenavService.open();
            })
        }else{
            this.isEmailGmail = false;
            this.sidenavService.sidenavData.next({data:  brand, type: this.typeGroup, isGmail: this.isEmailGmail});
            this.sidenavService.open();
        }
    }

    submit(data = null) {
        
        let submitData;
        if (data) {
            submitData = data;
        } else {
            this.storeData.name = this.formControl.value.slug ? this.formControl.value.slug : this.formControl.value;
            if(this.formControl.value.name){
                this.storeData.exists = true;
                this.storeData.slug = this.formControl.value.slug;
            } else {
                this.storeData.exists = false;
                this.storeData.slug = null;
            }
            submitData = this.storeData
        }
        
        if (submitData) {
            this.brandService.refreshRelBrandsCache();
            this.storeSubscribtion = this.brandService.store(submitData).subscribe(
                data => this.handleResponse(data),
                error => this.handleError(error, data),
            );
        } else {
            setTimeout(() => {
                this.inputField.nativeElement.focus();
            }, 200);
        }
    }

    updateBrand(id, storeData){
        this.brandService.refreshRelBrandsCache();
 
        this.updateSubscribtion = this.brandService.update(id, storeData).subscribe(
            data => this.handleUpdateResponse(data),
            error => this.handleUpdateError(error, storeData),
        );
    }

    handleResponse(data) {
        const dataSlug = data.data.slug
        this.notifier.notify('success', this.notifications.relationship);
        this.getBrands();
        this.listItems();
        this.resetInputForm();
        this.industryService.refreshRelIndustriesCache(this.type, this.typeData.slug);
        this.industryService.reloadIndustries.next();
        this.storeSubscribtion.unsubscribe();
        
        if(this.type == 'people'){
            this.personService.refreshRelPeopleCache(false);
        }

        if(this.type == 'companies'){
            const typesOfCompanies = this.typeData.types[0].slug;
            this.companyService.refreshRelCompaniesCache(typesOfCompanies);
        }
    }

    handleError(error, data) {
        this.brandService.deleteError(data).subscribe();
        this.notifier.notify('error', this.notifications.relationship_err);
        this.storeSubscribtion.unsubscribe();
    }

    handleUpdateResponse(data) {
        this.brandService.refreshTeamCache(this.type, this.typeData.slug);
        this.notifier.notify('success', this.brandNotifications.updated);
        this.getBrands();
        this.listItems();
        this.resetInputForm();
        this.brandService.refreshBrandCache(data.data.slug);
        this.industryService.refreshRelIndustriesCache(this.type, this.typeData.slug);
        this.industryService.reloadIndustries.next();
        this.updateSubscribtion.unsubscribe();
    }

    handleUpdateError(error, data) {
        this.notifier.notify('error', this.brandNotifications.update_err);
        this.updateSubscribtion.unsubscribe();
    }

    add(){
        if(this.brands?.length === 16){
            this.brandService.getRelationshipsAllData(this.type, this.typeData.slug).subscribe((res:any) => {
                this.selectedListItem = res.data
                this.openInput = true;
                setTimeout(() => {
                    this.inputField?.nativeElement.focus()
                }, 200);
                this.listItems();
            });
        } else {
            this.selectedListItem = this.brands
            this.openInput = true;
            setTimeout(() => {
                this.inputField?.nativeElement.focus()
            }, 200);
            this.listItems();
        }
    }

    resetInputForm() {
        this.openInput = false;
        this.storeData.name = '';
        this.autocomplete.closePanel();
        this.formControl.setValue(null);
    }

    deleteRelationship(slug, event) {
        event.stopPropagation();
        
        this.brandService.refreshRelBrandsCache();
        if(this.type == 'people'){
            this.personService.refreshRelPeopleCache(false);
        }
        if(this.type == 'companies'){
            const typesOfCompanies = this.typeData.types[0].slug;
            this.companyService.refreshRelCompaniesCache(typesOfCompanies);
        }

        this.brandService.removeRelationship(slug, this.type, this.typeData.id).subscribe (
            data => this.handleDeleteRelationshipResponse(data),
            error => this.handleDeleteRelationshipError(error),
        )
    }

    handleDeleteRelationshipResponse(data){
        this.notifier.notify('success', this.notifications.relationshipDel);
        this.getBrands();
        this.listItems();
    }
    
    handleDeleteRelationshipError(error){
        this.notifier.notify('error', this.notifications.relationshipDel_err);
    }
}
