import { COMMA, ENTER, TAB } from '@angular/cdk/keycodes';
import { AfterViewInit, Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { NotifierService } from 'angular-notifier';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { NotificationText } from 'src/app/components/lang/globals-en';
import { BrandService } from 'src/app/services/brand.service';
import { GeneralService } from 'src/app/services/general.service';
import { IndustryService } from 'src/app/services/industry.service';

@Component({
    selector: 'app-ss-industries',
    templateUrl: './ss-industries.component.html',
    styleUrls: ['./ss-industries.component.scss']
})
export class SsIndustriesComponent implements OnInit, AfterViewInit {
    @Input() typeData;
    @Input() type;
    notifications = NotificationText;
    openInput = false;
    separatorKeysCodes: number[] = [ENTER, COMMA, TAB];
    industry = new FormControl();
    industries: any[] = [];
    industryValues: any[] = [];
    allIndustries: any[];
    filteredIndustries: Observable<any[]>;
    relationshipIndustries: any[] = [];
    storeData = {
        industries: null,
        person_id: null,
        company_id: null,
        brand_id: null,
    };
    selectedListItem: any;
    loadedContent: boolean = false;

    @ViewChild('industryInput') industryInput: ElementRef<HTMLInputElement>;
    constructor(
        private industryService: IndustryService,
        private notifier: NotifierService,
        private generalService: GeneralService,
        private brandService: BrandService
    ) {
        this.industryService.reloadIndustries.subscribe(res => {
            this.getRelationshipIndustries();
        })
    }
    
    @HostListener('window:keyup.esc') onKeyUp() {
        this.resetInputForm();
    }

    ngOnInit(): void {
        this.getRelationshipIndustries()
        if(this.type == 'people' ){
            this.storeData.person_id = this.typeData.id
        } else if(this.type == 'brands') {
            this.storeData.brand_id = this.typeData.id
        }else {
            this.storeData.company_id = this.typeData.id
        }
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.getAllIndustriesList()
        }, 0);
    }

    getRelationshipIndustries(fromResponse = false){
        // if(this.type == 'people'){
        //     this.industryService.refreshRelIndustriesCache('people', 'person-1');
        // }
        this.industryService.getRelationships(this.type, this.typeData.slug).subscribe((res:any) => {
            this.relationshipIndustries = res.data;
            this.selectedListItem = res.data;
            if(fromResponse){
                this.brandService.reloadBrandIndustries.next(res.data);
            }
            setTimeout(() => {
                this.loadedContent = true;
            }, 100);
        })
    }

    mapIndustriesToSelect(){
        if(this.relationshipIndustries){
            this.relationshipIndustries.forEach(item => {
                this.industries.push(item.name)
                this.industryValues.push(item.id);
            });
        }
    }

    getAllIndustriesList(){
        this.industryService.getList().subscribe((res:any) => {
            if(this.selectedListItem){
                this.allIndustries = res.data.filter(ar => !this.selectedListItem.find(rm => (rm.slug === ar.slug)));
            }else{
                this.allIndustries = res.data
            }
            this.filterIndustries();
        })
    }

    filterIndustries() {
        this.filteredIndustries = this.industry.valueChanges.pipe(
            startWith(null),
            map((industry: string | null) => (industry ? this._filter(industry) : this.allIndustries.slice())),
        );
    }

    add(){
        this.openInput = true;
        setTimeout(() => {
            this.industryInput?.nativeElement.focus()
        }, 0);
        this.mapIndustriesToSelect();
    }

    submit(){
        var errorIndustries = [];
        this.storeData.industries = this.industries;
        this.industryValues.forEach(element => {
            if (!this.generalService.isNumber(element) && element){
                errorIndustries.push(element);
            }
        });
        
        this.industryService.store(this.storeData).subscribe(
            data => this.handleResponse(data),
            error => this.handleError(error, errorIndustries),
        );
    }

    handleResponse(data) {
        this.industryService.refreshRelIndustriesCache(this.type, this.typeData.slug);
        this.brandService.refreshBrandCache(this.typeData.slug);
        this.notifier.notify('success', this.notifications.relationship);
        this.resetInputForm();
        this.getRelationshipIndustries(true);
        this.getAllIndustriesList();
        this.loadedContent = false;
    }

    handleError(error, errorIndustries) {
        const dataErrorIndustries = {
            industries: errorIndustries
        }
        this.industryService.deleteError(dataErrorIndustries).subscribe();
        this.notifier.notify('error', this.notifications.relationship_err);
    }

    addIndustry(event: MatChipInputEvent): void {
        const value = (event.value || '').trim();
        if (value) {
            if(!this.industries.includes(value)) {
                this.industries.push(value);
                this.industryValues.push(value);
            }
        }
        if (event.input) {
            event.input.value = '';
        }
        this.industry.setValue(null);
    }

    removeIndustry(industry: any): void {
        const index = this.industries.indexOf(industry);
        const valueByName = this.industryValues.indexOf(industry);
        let valueId;
        this.allIndustries.forEach(item => {
            if(industry == item.name){
                valueId = item.id
            }
        });
        if (index >= 0) {
            this.industries.splice(index, 1);
        }
        if (valueByName >= 0) {
            this.industryValues.splice(valueByName, 1);
        }
        if (valueId) {
            const arrayIndex = this.industryValues.indexOf(valueId);
            this.industryValues.splice(arrayIndex, 1);
        }
    }

    selected(event: MatAutocompleteSelectedEvent): void {
        if(!this.industries.includes(event.option.viewValue)) {
            this.industries.push(event.option.viewValue);
            this.industryValues.push(event.option.value);
        }
        this.industryInput.nativeElement.value = '';
        this.industry.setValue(null);
        this.industry.disable();
        this.industry.enable();
    }

    private _filter(value: string): string[] {
        const filterValue = value.toString().toLowerCase();
        return this.allIndustries.filter(industry => industry.name.toString().toLowerCase().includes(filterValue));
    }

    deleteRelationship(slug){
        let typeDel;
        if (this.type == 'people') {
            typeDel = 'person'
        }else if(this.type == 'brands'){
            typeDel = 'brand'
        }else{
            typeDel = 'company'
        }
        let storeDelData = {
            custom_id: this.typeData.id,
            custom_name: typeDel,
        };
        this.industryService.deleteRelationship(slug, storeDelData).subscribe(
            data => this.handleDeleteResponse(data),
            error => this.handleDeleteError(error),
        )
    }

    handleDeleteResponse(data) {
        this.industryService.refreshRelIndustriesCache(this.type, this.typeData.slug);
        this.brandService.refreshBrandCache(this.typeData.slug);
        this.notifier.notify('success', this.notifications.relationshipDel);
        this.getRelationshipIndustries(true);
        this.getAllIndustriesList();
    }

    handleDeleteError(error) {
        this.notifier.notify('error', this.notifications.relationshipDel_err);
    }

    resetInputForm(){
        this.openInput = false;
        this.industries = []
        this.industryValues = []
        this.storeData.industries = '';
        this.industryInput.nativeElement.value = '';
        this.filterIndustries();
    }

}
