<div class="single-key_contact single-section active">
    <div class="key_contact-container single-container">
        <h2 class="f-sub-title-1">{{ text.title }}</h2>
        <div class="key_contacts">
            <app-loading-module class="loader" [ngClass]="{'loaded': loadedContent}"></app-loading-module>

            <div class="key_contacts-wrapper" id="key_contacts-wrapper" [ngClass]="{'hidden': !loadedContent}">
                <ng-container *ngFor="let key_contact of key_contacts; let i = index">
                    <div class="single-key_contact toolperson" *ngIf="i < 9" (click)="toggleRightSidenav(key_contact, $event)" data-tippy-delay="[350, 350]" [ngxTippy]="tippyTemplate"  [tippyProps]="tippyContent">
                        <button class="delete-button" (click)="deleteRelationship(key_contact.person.slug, $event)" >
                            <img src="./../../../../../assets/img/off_close.png" alt="delete">
                        </button>
                        <div class="key_contact-logo">
                            <ng-container *ngIf="key_contact.person.avatar; else noLogoImg">
                                <cl-image _ngcontent-ywn-2="" public-id="mypic" class="thumbnail inline"
                                    ng-reflect-public-id="mypic">
                                    <img src="{{imageUrl}}/{{ key_contact.person.avatar }}">
                                </cl-image>
                            </ng-container>
                            <ng-template #noLogoImg>
                                <img src="./../../../../../assets/img/default-logo.png" alt="avatar">
                            </ng-template>
                        </div>
                        <div class="key_contact-info">
                            <h3>{{ key_contact.person.name }}</h3>
                            <ng-container  *ngFor="let position of key_contact.person.positions; let isLast=last; let i = index">
                                <p>{{position.name }}{{isLast ? ' ' : ', '}}</p>
                            </ng-container>
                        </div>
                        <template #tippyTemplate>
                            <app-tooltip [data]="key_contact.person" [typePosition]="typeGroup"></app-tooltip>
                        </template>
                    </div>
                    <ng-container *ngIf="i == 9">
                        <div class="single-key_contact toolperson" (click)="toggleRightSidenav(key_contact, $event)" data-tippy-delay="[350, 350]" 
                            *ngIf="key_contacts.length == 9; else viewAllContent" [ngxTippy]="tippyTemplate"  [tippyProps]="tippyContent">
                            <button class="delete-button" (click)="deleteRelationship(key_contact.person.slug, $event)" >×</button>
                            <div class="key_contact-logo">
                                <cl-image _ngcontent-ywn-2="" public-id="mypic" class="thumbnail inline"
                                    ng-reflect-public-id="mypic">
                                    <img src="{{imageUrl}}/{{ key_contact.person.avatar }}">
                                </cl-image>
                            </div>
                        </div>
                        <ng-template #viewAllContent>
                            <div class="more-key_contacts">
                                <div class="view-all-button">
                                    <p class="key_contact-count f-sub-header-2 font-blue"> + <span>{{ dataCount - 9}}</span></p>
                                    <a class="view-all-key_contacts" (click)="showAllList()"> <img src="./../../../../../assets/img/view-all.png" alt="View All"> </a>
                                    <button class="f-button font-blue add" [ngClass]="{'open': openInput, 'view-all-and-add-key': key_contacts && key_contacts.length > 14}" (click)="add()">+{{ text.add }}</button> 
                                </div>
                            </div>
                        </ng-template>
                    </ng-container>
                </ng-container>
                <em *ngIf="!key_contacts" class="no-key_contacts f-body-2">{{ text.noMsg }}</em>
                <div class="key_contact-input" [ngClass]="{open: openInput}">
                    <mat-form-field appearance="standard">
                        <mat-label>{{ text.new }}</mat-label>
                        <input
                            matInput
                            type="text"
                            #inputField
                            #trigger="matAutocompleteTrigger"
                            (keyup.enter)="onInputEnter($event.target.value)"
                            [formControl]="formControl" 
                            [matAutocomplete]="auto">
                        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                            <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                                {{option.name}}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
                <ng-container *ngIf="key_contacts?.length < 10">
                    <button class="f-button font-blue add" [ngClass]="{'open': openInput, 'view-all-and-add-key': key_contacts && key_contacts.length > 14}" (click)="add()">+{{ text.add }}</button> 
                </ng-container>
                <button class="f-button font-blue save" [ngClass]="{'open': openInput}" (click)="submit()">{{ text.save }}</button> 
            </div>
        </div>
    </div>
</div>

<app-sidenav [dataNav]="keyContactClicked" [typePosition]="typeGroup"></app-sidenav>