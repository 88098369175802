<div class="single-company single-section active">
    <div class="company-container single-container">
        <h2 class="f-sub-title-1">{{companyType.plural | titlecase }}</h2>
        <div class="companies">
            <app-loading-module class="loader" [ngClass]="{'loaded': loadedContent}"></app-loading-module>

            <div class="companies-wrapper" id="companies-wrapper" [ngClass]="{'hidden': !loadedContent}">
                <ng-container *ngFor="let company of companies; let i = index">
                    <div class="single-company toolCompany" *ngIf="i < 14" 
                        (click)="toggleRightSidenav(company, $event)" data-tippy-delay="[350, 350]"
                        [ngxTippy]="tippyTemplate" [tippyProps]="tippyContent">
                        <button class="delete-button" (click)="deleteRelationship(company.slug, $event)" >
                            <img src="./../../../../../assets/img/off_close.png" alt="delete">
                        </button>
                        <div class="company-logo">
                            <ng-container *ngIf="company.logo; else noLogoImg">
                                <cl-image _ngcontent-ywn-2="" public-id="mypic" class="thumbnail inline"
                                    ng-reflect-public-id="mypic">
                                    <img src="{{imageUrl}}/{{ company.logo }}">
                                </cl-image>
                            </ng-container>
                            <ng-template #noLogoImg>
                                <img src="./../../../../../assets/img/default-logo.png" alt="avatar">
                            </ng-template>
                        </div>
                        <template #tippyTemplate>
                            <app-tooltip [data]="company" [typePosition]="typeGroup"></app-tooltip>
                        </template>
                    </div>
                    <ng-container *ngIf="i == 14">
                        <div class="single-company toolCompany"  (click)="toggleRightSidenav(company, $event)" data-tippy-delay="[350, 350]"
                        [ngxTippy]="tippyTemplate" [tippyProps]="tippyContent" *ngIf="companies.length == 15; else viewAllContent" >
                            <button class="delete-button" (click)="deleteRelationship(company.slug, $event)">
                                <img src="./../../../../../assets/img/off_close.png" alt="delete">
                            </button>
                            <div class="company-logo" >
                                <ng-container *ngIf="company.logo; else noLogoImg">
                                    <cl-image _ngcontent-ywn-2="" public-id="mypic" class="thumbnail inline"
                                        ng-reflect-public-id="mypic">
                                        <img src="{{imageUrl}}/{{ company.logo }}">
                                    </cl-image>
                                </ng-container>
                                <ng-template #noLogoImg>
                                    <img src="./../../../../../assets/img/default-logo.png" alt="avatar">
                                </ng-template>
                            </div>
                        </div>
                        <ng-template #viewAllContent>
                            <div class="more-companies">
                                <div class="view-all-button">
                                    <p class="company-count f-sub-header-2 font-blue"> + <span>{{ dataCount - 14}}</span></p>
                                    <a (click)="showAllList()" class="view-all-companies"> <img src="./../../../../../assets/img/view-all.png" alt="View All"> </a>
                                </div>
                            </div>
                        </ng-template>
                    </ng-container>
                </ng-container>
                <em *ngIf="!companies || companies.length == 0" class="no-companies f-body-2">No {{companyType.plural | titlecase }}</em>

                <div class="company-input" [ngClass]="{open: openInput}">
                    <mat-form-field appearance="standard">
                        <mat-label>New Company</mat-label>
                        <input
                            matInput
                            type="text"
                            #inputField
                            #trigger="matAutocompleteTrigger"
                            (keyup.enter)="onInputEnter($event.target.value)"
                            [formControl]="formControl" 
                            [matAutocomplete]="auto"
                            >
                        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" [matAutocompletePosition]="'auto'">
                            <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                                {{option.name}}
                            </mat-option>
                        </mat-autocomplete>
                    </mat-form-field>
                </div>
                <button class="f-button font-blue add" [ngClass]="{'open': openInput, 'view-all-and-add-key': companies && companies.length > 15}" (click)="add()">+Add</button> 
                <button class="f-button font-blue save" [ngClass]="{open: openInput}" (click)="submit()">Save</button> 
            </div>
        </div>
    </div>
</div>

<app-sidenav [dataNav]="brandClicked"></app-sidenav>