import { COMMA, ENTER, TAB } from '@angular/cdk/keycodes';
import { Component, ElementRef, ViewChild, Inject, OnInit, Optional, HostListener, OnDestroy, Directive } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { PositionService } from 'src/app/services/position.service';
import { LoginText, NotificationText, ImageUrl } from '../../../lang/globals-en';
import { OpendialogService } from 'src/app/services/opendialog.service';
import { CompanyTypesService } from 'src/app/services/company-types.service';
import { UploadService } from 'src/app/services/upload.service';
import { UpdateImageService } from 'src/app/services/updateimage.service';

@Component({
  selector: 'app-edit-dialog',
  templateUrl: './edit-dialog.component.html',
  styleUrls: ['./edit-dialog.component.scss']
})

export class EditDialogComponent implements OnInit {
  modalFormGroup: FormGroup;
  separatorKeysCodes: number[] = [ENTER, COMMA, TAB];
  notifications = NotificationText;
  text = LoginText;
  error: null;
  errorStatus: null;
  type: any;
  name;
  profilePicture: File; 
  
  countryFormControl = new FormControl();
  country;

  position = new FormControl();
  filteredPositions: Observable<any[]>;
  positions: any[] = [];
  positionValues: any[] = [];
  allUnselectedPositions: any[];
  positonIdSelected: any[] = [];

  companyType = new FormControl();
  filteredCompanyTypes: Observable<any[]>;
  companyTypes: any[] = [];
  companyTypesData;
  companyTypeValues: any[] = [];
  allCompanyTypes: any[];

  focusInput: boolean = false;

  pondOptions = {
      class: 'my-filepond',
      multiple: false,
      labelIdle: '<img src="/assets/img/default-logo.png" alt="logo">',
      acceptedFileTypes: 'image/jpeg, image/png',
      imageCropAspectRatio: '1:1',
      stylePanelLayout: 'compact circle',
      styleLoadIndicatorPosition: 'center bottom',
      styleProgressIndicatorPosition: 'center bottom',
      styleButtonRemoveItemPosition: 'center bottom',
      styleButtonProcessItemPosition: 'center bottom',
  };

  pondFiles;
  imageUrl = ImageUrl;
  imageUrlType;
  imageSlug;
  personPositions;
  personSelectPositions: any[] = [];

  industry = new FormControl();
  filteredIndustries: Observable<any[]>;
  industries: any[] = [];
  industryValues: any[] = [];
  allUnselectedIndustries: any[];
  brandSelectIndustries: any[] = [];
  showSocialsRow: boolean = false;

  @ViewChild('positionInput') positionInput: ElementRef<HTMLInputElement>;
  @ViewChild('companyTypeInput') companyTypeInput: ElementRef<HTMLInputElement>;
  @ViewChild('nameInput') nameInput: ElementRef;
  @ViewChild('myPond') myPond: any;
  @ViewChild('industryInput') industryInput: ElementRef<HTMLInputElement>;
  
  public  constructor(
    @Optional() public dialogRef: MatDialogRef<EditDialogComponent>,
    private formBuilder: FormBuilder,
    private positionService: PositionService,
    private companyTypeService: CompanyTypesService,
    private openDialogService: OpendialogService,
    private uploadService: UploadService,
    private updateImageService: UpdateImageService,
    @Inject(MAT_DIALOG_DATA) public data
) {
    this.country = this.data.countries;
    this.type = this.data.type;

    if(this.data.ssData?.companyTypes){
        this.companyTypesData = this.data.ssData.companyTypes;
    }
}

@HostListener('window:keyup.esc') onKeyUp() {
    this.dialogRef.close();
}

ngOnInit(): void {
    if (this.type.group == 'people' || this.type.group == 'person-key-contact') {
        this.positionsCheck();
        this.getPositions();
        // this.personPositions = this.data.allSingleData.positions;
    }
    if (this.type.group == 'brands') {
        if(this.data.allSingleData?.industries) {
            this.data.allSingleData.industries.forEach(item => {
                this.brandSelectIndustries.push(item);
                this.industries.push(item.name);
                this.industryValues.push(item.id);
            });
        }
        this.getIndustries();
    }
    
    if (this.type.group == 'company') {
        this.getCompanyTypes();
        this.modalFormGroup = this.formBuilder.group({
            name: [null, [Validators.required]],
            website: [null, [Validators.pattern('^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?\/?$')]],
            email: [null, [Validators.email]],
            logo: '',
            avatar: '',
            image_token: '',
            description: '',
            phone: '',
            address: '',
            country_id: '',
            country_select: '',
            behance: '',
            facebook: '',
            linkedin: '',
            instagram: '',
            twitter: '',
            vimeo: '',
            dribbble: '',
            pinterest: '',
            youtube: '',
            positions: [null, null],
            industries: [null, null],
            companyTypes: [null, null],
            person_id: '',
            brand_id: '',
            company_id: '',
        });
    } else {
        this.modalFormGroup = this.formBuilder.group({
            name: [null, [Validators.required]],
            person: '',
            website: [null, [Validators.pattern('^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?\/?$')]],
            email: [null, [Validators.email]],
            logo: '',
            avatar: '',
            image_token: '',
            description: '',
            phone: '',
            address: '',
            country_id: '',
            country_select: '',
            behance: '',
            facebook: '',
            linkedin: '',
            instagram: '',
            twitter: '',
            vimeo: '',
            dribbble: '',
            pinterest: '',
            youtube: '',
            positions: [null, null],
            industries: [null, null],
            companyTypes: [null, null],
            person_id: '',
            brand_id: '',
            company_id: '',
        });
    }
    
    if (this.data.ssData) {
        if(this.data.ssData.person){
            this.modalFormGroup.get('name').setValue(this.data.ssData.person);
            this.modalFormGroup.get('person').setValue(this.data.ssData.person);
        }else{
            this.modalFormGroup.get('name').setValue(this.data.ssData.name);
        }
        this.modalFormGroup.get('person_id').setValue(this.data.ssData.person_id);
        this.modalFormGroup.get('brand_id').setValue(this.data.ssData.brand_id);
        this.modalFormGroup.get('company_id').setValue(this.data.ssData.company_id);
        this.modalFormGroup.get('companyTypes').setValue(this.data.ssData.companyTypes);

        this.modalFormGroup.get('website').setValue(this.data.allSingleData?.website);
        this.modalFormGroup.get('email').setValue(this.data.allSingleData?.email);
        this.modalFormGroup.get('description').setValue(this.data.allSingleData?.description);
        this.modalFormGroup.get('phone').setValue(this.data.allSingleData?.phone);
        this.modalFormGroup.get('address').setValue(this.data.allSingleData?.address);

        if(this.data.allSingleData?.country){
            const selectedCountry = this.country.filter(country => country.name == this.data.allSingleData?.country.name)[0];
            this.modalFormGroup.get('country_select').setValue(selectedCountry);
        }

        this.modalFormGroup.get('image_token').setValue(this.data.allSingleData?.image_token);
        if(this.type.group == 'brands'){
            this.imageUrlType = this.imageUrl.brands + '/' + this.data.allSingleData?.logo;       
            this.imageSlug = this.data.allSingleData?.logo
            this.modalFormGroup.get('logo').setValue(this.data.allSingleData?.logo);
        }else if(this.type.group == 'people' || this.type.group == 'person-key-contact') {
            this.imageUrlType = this.imageUrl.people + '/' + this.data.allSingleData?.avatar;
            this.imageSlug = this.data.allSingleData?.avatar
            this.modalFormGroup.get('avatar').setValue(this.data.allSingleData?.avatar);
        }else{
            this.imageUrlType = this.imageUrl.company + '/' + this.data.allSingleData?.logo;
            this.imageSlug = this.data.allSingleData?.logo
            this.modalFormGroup.get('logo').setValue(this.data.allSingleData?.logo);
        }
        
        if(this.imageSlug){
            this.pondFiles = [
                this.imageUrlType
            ]
        }
        
        this.modalFormGroup.get('behance').setValue(this.data.allSingleData?.behance);
        this.modalFormGroup.get('facebook').setValue(this.data.allSingleData?.facebook);
        this.modalFormGroup.get('linkedin').setValue(this.data.allSingleData?.linkedin);
        this.modalFormGroup.get('instagram').setValue(this.data.allSingleData?.instagram);
        this.modalFormGroup.get('twitter').setValue(this.data.allSingleData?.twitter);
        this.modalFormGroup.get('dribbble').setValue(this.data.allSingleData?.dribbble);
        this.modalFormGroup.get('vimeo').setValue(this.data.allSingleData?.vimeo);
        this.modalFormGroup.get('pinterest').setValue(this.data.allSingleData?.pinterest);
        this.modalFormGroup.get('youtube').setValue(this.data.allSingleData?.youtube);
    }
    this.focusInput = true;
    const fieldsToCheck: string[] = ['behance','facebook', 'linkedin', 'twitter', 'instagram', 'vimeo', 'dribbble','pinterest','youtube'];

    fieldsToCheck.forEach(field => {
        if (this.data.allSingleData[field] !== null) {
            this.showSocialsRow = true;
        }
    });
}

    filePondAddFile(event: any) {
        if(!event.error){
            this.profilePicture = event.pond.getFile().file;
        } else {
            event.pond.removeFiles();
        }
    }

    filePondRemoveFilde(event: any){
        this.profilePicture = null;
    }

    submit(): void {
        this.error = null;
        this.errorStatus = null;
        this.modalFormGroup.get('positions').setValue(this.positionValues);
        this.modalFormGroup.get('industries').setValue(this.industryValues);
        this.modalFormGroup.get('country_id').setValue(this.modalFormGroup.value.country_select.id);

        if(this.profilePicture){
            if(this.profilePicture.name !== this.imageSlug ){
                if(this.imageSlug){
                    // NEED TO DELETE THE OLD IMAGE AND ADD THE NEW ONE
                    this.uploadService.deleteImage(this.data.allSingleData.image_token).subscribe((res:any) => {
                        this.modalFormGroup.get('logo').setValue(null);
                        this.modalFormGroup.get('avatar').setValue(null);
                        this.modalFormGroup.get('image_token').setValue(null);
                        if (res.result === 'ok') {
                           this.uploadImage();
                        }
                    })
                } else {
                    // DOES NOT NEED TO DELETE THE OLD IMAGE BECAUSE IT DOES NOT HAVE ONE ONLY ADD NEW ONE
                    this.uploadImage();
                }
            } else {
                // DO NOT UPDATE IMAGE BECAUSE HAS NOT BEEN CHANGED
                this.updateData()
            }
        }else{
            if(this.imageSlug){
                // DELETE EXISTING IMAGE AND DO NOT UPLOAD A NEW ONE
                this.uploadService.deleteImage(this.data.allSingleData.image_token).subscribe((res:any) => {
                    this.modalFormGroup.get('logo').setValue(null);
                    this.modalFormGroup.get('avatar').setValue(null);
                    this.modalFormGroup.get('image_token').setValue(null);
                    if (res.result === 'ok') {
                        this.updateData()
                    }
                })
            } else {
                // DO NOT UPDATE IMAGE BECAUSE HAS NOT BEEN FILLED
                this.updateData()
            }
        }
    }

    uploadImage(){
        this.uploadService.uploadImage(this.profilePicture, this.type.group).subscribe( res => {
            if (res.url) {
                let filename = res.url.substring(res.url.lastIndexOf("/") + 1, res.url.length);
                this.modalFormGroup.get('logo').setValue(filename);
                this.modalFormGroup.get('avatar').setValue(filename);
                this.modalFormGroup.get('image_token').setValue(res.public_id);
                this.updateData()
            }
        })
    }

    updateData(){
        var positionChanged:boolean;
        if(this.modalFormGroup.value?.positions){
            var currentPositions = this.modalFormGroup.value.positions;
        }

        if(this.type.group == 'people' || this.type.group == 'person-key-contact'){
            if(currentPositions.toString() === this.positonIdSelected.toString()){
                positionChanged = false;
            }else{
                positionChanged = true;
            }
        }
        this.openDialogService.updateModalData.next({ type: this.type, data: this.modalFormGroup.value, slug: this.data.allSingleData.slug, positionChanged: positionChanged });
        this.dialogRef.close();
    }

    getPositions() {
        if(this.positionValues){
            this.allUnselectedPositions  = this.data.positions.filter(ar => !this.personSelectPositions.find(rm => (rm.slug === ar.slug)));
        }else{
            this.allUnselectedPositions = this.data.positions;
        }
        this.filterPositions();
    }

    positionsCheck(){
        if(this.data.allSingleData?.positions){
            this.data.allSingleData.positions.forEach(item => {
                this.personSelectPositions.push(item);
                this.positions.push(item.name);
                this.positionValues.push(item.id);
                this.positonIdSelected.push(item.id);
            });
        }
    }

    filterPositions() {
        this.filteredPositions = this.position.valueChanges.pipe(
            startWith(null),
            map((position: string | null) => (position ? this._filterPositions(position) : this.allUnselectedPositions.slice())),
        );
    }

    addPosition(event: MatChipInputEvent): void {
        const value = (event.value || '').trim();
        if (value) {
            if(!this.positions.includes(value)) {
                this.positions.push(value);
                this.positionValues.push(value);
            }
        }
        if (event.input) {
            event.input.value = '';
        }
        this.position.setValue(null);
    }

    removePosition(position: string): void {
        const index = this.positions.indexOf(position);
        const valueByName = this.positionValues.indexOf(position);
        let valueId;

        this.data.positions.forEach(item => {
            if(position == item.name){
                valueId = item.id
            }
        });

        if (index >= 0) {
            this.positions.splice(index, 1);
        }
        if (valueByName >= 0) {
            this.positionValues.splice(valueByName, 1);
        }
        if (valueId) {
            const arrayIndex = this.positionValues.indexOf(valueId);
            this.positionValues.splice(arrayIndex, 1);
        }
        this.filterPositions();
    }

    selectedPosition(event: MatAutocompleteSelectedEvent): void {
        this.personSelectPositions.push(event.option.viewValue);
        if(!this.positions.includes(event.option.viewValue)) {
            this.positions.push(event.option.viewValue);
            this.positionValues.push(event.option.value);
        }
        this.positionInput.nativeElement.value = '';
        this.position.setValue(null);
        this.position.disable();
        this.position.enable();
        this.getPositions();
    }

    private _filterPositions(value: string): string[] {
        const filterValue = value.toString().toLowerCase();
        return this.allUnselectedPositions.filter(position => position.name.toString().toLowerCase().includes(filterValue));
    }

    getCompanyTypes() {
        this.allCompanyTypes = this.data.allCompanyTypes;
        this.filterCompanyTypes();
    }

    filterCompanyTypes() {
        this.filteredCompanyTypes = this.companyType.valueChanges.pipe(
            startWith(null),
            map((companyType: string | null) => (companyType ? this._filterCompanyTypes(companyType) : this.allCompanyTypes.slice())),
        );
    }

    addCompanyType(event: MatChipInputEvent): void {
        const value = (event.value || '').trim();
        
        if (value) {
            if(!this.companyTypes.includes(value)) {
                this.companyTypes.push(value);
                this.companyTypeValues.push(value);
                this.modalFormGroup.get('companyTypes').setValue(this.companyTypeValues);
            }
        }
        if (event.input) {
            event.input.value = '';
        }
        this.companyType.setValue(null);
    }

    removeCompanyType(companyType: string): void {
        const index = this.companyTypes.indexOf(companyType);
        const valueByName = this.companyTypeValues.indexOf(companyType);
        let valueId;
        this.allCompanyTypes.forEach(item => {
            if(companyType == item.name){
                valueId = item.id
            }
        });

        if (index >= 0) {
            this.companyTypes.splice(index, 1);
        }
        if (valueByName >= 0) {
            this.companyTypeValues.splice(valueByName, 1);
            this.modalFormGroup.get('companyTypes').setValue(this.companyTypeValues);
        }
        if (valueId) {
            const arrayIndex = this.companyTypeValues.indexOf(valueId);
            this.companyTypeValues.splice(arrayIndex, 1);
            this.modalFormGroup.get('companyTypes').setValue(this.companyTypeValues);
        }
    }

    selectedCompanyType(event: MatAutocompleteSelectedEvent): void {
        if(!this.companyTypes.includes(event.option.viewValue)) {
            this.companyTypes.push(event.option.viewValue);
            this.companyTypeValues.push(event.option.value);
            this.modalFormGroup.get('companyTypes').setValue(this.companyTypeValues);
        }
        this.companyTypeInput.nativeElement.value = '';
        this.companyType.setValue(null);
        this.companyType.disable();
        this.companyType.enable();
    }

    private _filterCompanyTypes(value: string): string[] {
        const filterValue = value.toString().toLowerCase();
        return this.allCompanyTypes.filter(companyType => companyType.name.toString().toLowerCase().includes(filterValue));
    }

    // -- INDUSTRY INPUT WITH SELECT 2 -- //
    getIndustries() {
        if(this.brandSelectIndustries){
            this.allUnselectedIndustries = this.data.industries.filter(ar => !this.brandSelectIndustries.find(rm => (rm.name === ar.name)));
        }else{
            this.allUnselectedIndustries = this.data.industries;
        }
        this.filterIndustries();
    }
    
    filterIndustries() {
        this.filteredIndustries = this.industry.valueChanges.pipe(
            startWith(null),
            map((industry: string | null) => (industry ? this._filterIndustries(industry) : this.allUnselectedIndustries.slice())),
        );
    }

    private _filterIndustries(value: string): string[] {
        const filterValue = value.toString().toLowerCase();
        return this.allUnselectedIndustries.filter(industry => industry.name.toString().toLowerCase().includes(filterValue));
    }
    
    addIndustry(event: MatChipInputEvent): void {
        const value = (event.value || '').trim();
        if (value) {
            if(!this.industries.includes(value)) {
                this.industries.push(value);
                this.industryValues.push(value);
            }
        }
        if (event.input) {
            event.input.value = '';
        }
        this.industry.setValue(null);
    }

    removeIndustry(industry: string): void {
        
        const index = this.industries.indexOf(industry);
        const valueByName = this.industryValues.indexOf(industry);
        let valueId;
        this.data.industries.forEach(item => {
            if(industry == item.name){
                valueId = item.id
            }
        });

        if (index >= 0) {
            this.industries.splice(index, 1);
        }
        if (valueByName >= 0) {
            this.industryValues.splice(valueByName, 1);
        }
        if (valueId) {
            const arrayIndex = this.industryValues.indexOf(valueId);
            this.industryValues.splice(arrayIndex, 1);
        }
    }
    
    selectedIndustry(event: MatAutocompleteSelectedEvent): void {
        this.brandSelectIndustries.push(event.option.viewValue);

        if(!this.industries.includes(event.option.viewValue)) {
            this.industries.push(event.option.viewValue);
            this.industryValues.push(event.option.value);
        }
        this.industryInput.nativeElement.value = '';
        this.industry.setValue(null);
        this.industry.disable();
        this.industry.enable();
        this.getIndustries();
    }
    // -- END OF INDUSTRY INPUT WITH SELECT 2 -- //
    showSocials(){
        this.showSocialsRow = true;
    }
}