import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { AuthService } from 'src/app/services/auth/auth.service';
import { TokenService } from 'src/app/services/auth/token.service';
import { NotificationText, ResetText } from '../../lang/globals-en';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
    notifications = NotificationText;
    text = ResetText;
    form: FormGroup;
    hide = true;
    confirm_hide = true;
    error = null;
    errorStatus = null;

    constructor(private authService:AuthService,
                private router: Router,
                private route: ActivatedRoute,
                private fb: FormBuilder,
                private notifier: NotifierService) { }

    ngOnInit(): void {
        this.form = this.fb.group({
            password: [null, [Validators.required, Validators.minLength(8)]],
            password_confirmation: [null, [Validators.required, Validators.minLength(8)]],
            token: this.route.snapshot.queryParamMap.get('token'),
            user: this.route.snapshot.queryParamMap.get('user')
        });
    }

    onSubmit(){
        return this.authService.changePassword(this.form.value).subscribe(
            data => this.handleResponse(data),
            error => this.handleError(error)
        );
    }

    handleResponse(data) {
        this.notifier.notify('success', this.notifications.changed);
        this.router.navigateByUrl('/login');
    }
    handleError(error) {
        this.notifier.notify('error', this.notifications.change_error);
        this.error = error.error;
        this.errorStatus = error.status;
    }

}
