import { CommonModule } from "@angular/common";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { NgMaterialModule } from 'src/app/ng-material/ng-material.module';
import { MatSelectCountryModule } from '@angular-material-extensions/select-country';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { TooltipComponent } from "./tooltip.component";
import { NgxTippyModule } from "ngx-tippy-wrapper";


@NgModule({
    declarations: [
        TooltipComponent
    ],
    imports: [
        CommonModule,
        NgMaterialModule,
        MatSelectCountryModule,
        HttpClientModule,
        ReactiveFormsModule,
        NgxTippyModule
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ],

    exports: [
        TooltipComponent,
        NgxTippyModule
    ]

})


export class TooltipModule{ 
}